import React, { useEffect, useState } from 'react'
import CardsPage from '../CardsPage/CardsPage';
import axios from 'axios';
import { BASE_URL } from '../../insightConfig/insightConfig';

function Mytest({ heading2 }) {
  const [mytest, setMytest] = useState([]);
  console.log(heading2)
  useEffect(() => {
    axios.get(BASE_URL + `/mytestseries`).then((response) => {
      setMytest(response.data.data)
      console.log(response.data.data)
    }).catch((error) => {
      console.log(error)
    })
  }, []);
  return (
    <>
      <CardsPage cards={mytest} heading2='' />
    </>
  )
}

export default Mytest;