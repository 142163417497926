import { createContext, useState, useContext } from "react";

// Step 1: Create the context with the default object value
const QuizDetailsContext = createContext({
    subjectURL: 'default subject',
    sectionURL: 'default section'
});

// Step 2: Create the provider component
const QuizDetailsProvider = ({ children }) => {
    const [quizDetails, setQuizDetails] = useState({
        subjects: [],
        attemptedQuestionIds: [],
        subject: '',
        section: '',
        subjectURL: '',
        sectionURL: '',
        subjectId: 0,
        sectionId: 0,
        questions: [],
        currentQuestionIndex: 0,
        questionIdParams: '',
    });

    // Function to update a single property
    const updateQuizProperty = (key, value) => {
        setQuizDetails(prevDetails => ({
            ...prevDetails,  // Copy the existing object
            [key]: value     // Update the specific property
        }));
    };

    return (
        <QuizDetailsContext.Provider value={{ quizDetails, setQuizDetails, updateQuizProperty }}>
            {children}
        </QuizDetailsContext.Provider>
    );
};

// Step 3: Create a custom hook for consuming the context
const useQuizDetails = () => {
    const context = useContext(QuizDetailsContext);
    if (!context) {
        throw new Error("useQuizDetails must be used within a QuizDetailsProvider");
    }
    return context;
};

export { QuizDetailsProvider, useQuizDetails };
