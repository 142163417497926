import React, { useEffect, useState } from 'react';
import "../TestStartComponent/teststart.css";
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Content from '../AllPageContent/AllPageContent';
import ResultPreview from '../ResultPreview/ResultPreview';
import ResultPreview1 from '../ResultPreview/ResultPreview1';
import YoutubeModal from '../YoutubeModal/YoutubeModal';
import { Modal } from 'react-bootstrap';
import { BASE_URL } from '../../insightConfig/insightConfig';
import axios from 'axios';
import { SlArrowLeft } from 'react-icons/sl';

function Finalresult() {
    const [isOpen, setIsOpen] = useState(false);
    const { state: { testData } } = useLocation();
    // const [testData, setTestData] = useState({})
    const [testReport, setTestreport] = useState({})
    const navigate = useNavigate()
    const { totalquestion, correct, wrongquestions, notVisitedQuestions, allIndiaRank, testcorrectmarks, testwrongmarks } = testReport


    useEffect(() => {
        axios.get(BASE_URL + `/get-test-report/${testData.testSeriesSn}/${testData.PackageID}`).then((respnse) => {
            setTestreport(respnse.data.report)
        }).catch(error => {
            console.log(error)
        })
        // axios.get(BASE_URL + `/test-data/${testData.PackageID}`).then((respnse) => {
        //     setTestData(respnse.data.testData)
        //     console.log(respnse.data.testData)
        // }).catch(error => {
        //     console.log(error)
        // })
    }, [])
    console.log(testReport)


    // useEffect(() => {
    //     setPrevPath(location.pathname);
    // }, [location.pathname, setPrevPath]);

    // const [isOpen, setIsOpen] = useState(false);
    // const initialTime = testDuration * 60; // 120 minutes converted to seconds
    // const [timeLeft, setTimeLeft] = useState(initialTime);
    // const formatTime = (timeInSeconds) => {
    //     const minutes = Math.floor(timeInSeconds / 60);
    //     const seconds = timeInSeconds % 60;
    //     return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    // };

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const currentTestSeriesTitleURL = JSON.parse(localStorage.getItem("CurrentTestSeriesTitleURL"));
    console.log(testData)
    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-lg-3 test-background p-3 pt-1'>
                        <button onClick={() => navigate('/testseries/' + currentTestSeriesTitleURL)} className='btn btn-sm btn-info mb-3'><SlArrowLeft /> Back</button>
                        <div className='d-grid gap-3'>
                            <button type="button" className="btn bg-darkblue btn-md py-3 d-md-none">
                                {testData?.Type === "MCQ" ? <>PTS-{testData.AccessCode}</> : <>MTS-{testData.AccessCode}</>} : {testData.Title}
                                <hr className='my-1' />
                                {testData.QuestionCount} Ques | {testData.Duration} min | {testData.MarksTotal} marks
                            </button>
                            <button type="button" className="btn bg-darkorange btn-md py-3">RESULT</button>
                            <Link to="/TestResultDetailView" state={{ testReport, testData }} type="button" className="btn bg-darkorange btn-md py-3">VIEW REPORT</Link>
                            <button type="button" className="btn bg-darkorange btn-md py-3" onClick={() => window.open(testData?.ModelAnswerPDF, '_blank')}>EXPLANATION</button>
                            {testData?.VideoUrl != '' && testData.VideoUrl != 'N' && <button type="button" onClick={openModal} className="btn bg-darkgreen btn-md p-3">VIDEO DISCUSSION</button>}
                        </div>
                    </div>
                    <div className='col-lg-9 teststart-padding'>

                        <div className='container-fluid  d-none d-md-block'>
                            <div className='row'>
                                <button type="button" className="btn bg-darkblue btn-md py-3">
                                    {testData?.Type === "MCQ" ? <>PTS-{testData.AccessCode}</> : <>MTS-{testData.AccessCode}</>} : {testData.Title}
                                    <hr className='my-1' />
                                    {testData.QuestionCount} Ques | {testData.Duration} min | {testData.MarksTotal} marks
                                </button>
                            </div>
                        </div>
                        <ResultPreview1 totalQuestions={totalquestion} correctQuestions={correct} wrongQuestions={wrongquestions} correctMarks={testcorrectmarks} wrongMarks={testwrongmarks} notVisitedQuestions={notVisitedQuestions} />
                    </div>
                </div>
            </div>
            <Modal show={isOpen} centered className="animate__animated animate__fadeIn mobileyoutube">
                <YoutubeModal closeModal={closeModal} VideoId={testData.VideoUrl} />
            </Modal>
        </>
    )
}

export default Finalresult;
