import React from 'react';
import { IoCalendar } from 'react-icons/io5';
import Content from '../../../AllPageContent/AllPageContent';

function CivilScheduleContent({ items }) {
    if (items.length<1) {
        return <p>Schedule does not exist</p>;
    }

    return (
        <>
            {items?.map((item, index) => (
                <div className='Schedule-Content-backaground m-3' key={index}>
                    <div className='row'>
                        <div className='col-lg-3 text-center px-3 py-3'>
                            <h5 className='fw-bold fs-6 text-white text-center'>{item.Title}</h5>
                            <hr className='text-white' />
                            {item?.PublishDate && (
                                <div className='text-white py-2'>
                                    <IoCalendar className='fs-4 mb-2 me-2' />
                                    {new Date(item.PublishDate).toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' })}
                                </div>
                            )}
                        </div>
                        <div className='col-lg-9 bg-schedule p-4'>
                            <Content paragraphs={item?.Description || []} />
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
}

export default CivilScheduleContent;

