import React, { useEffect, useState } from 'react';
import "./QuestionLayout.css"
import Questionsummary from '../Questionsummary/Questionsummary';
import Questionpreview2 from '../Questionpreview/Questionpreview2';
import ResultPreview from '../../ResultPreview/ResultPreview';
import { Link, useNavigate } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { finalsubmittest } from '../../DynamicCourse/api';
import axios from 'axios';
import { BASE_URL } from '../../../insightConfig/insightConfig';
import { useQuizDetails } from '../../Homequiz/QuizContext';

function QuestionLayout2() {
  const navigate = useNavigate();
  // console.log(quizDetails);
  const [quizQuestions, setQuizQuestions] = useState([])
  // const [notVisitedQuestions, setNotVisitedQuestions] = useState([])
  const [suggestedQuestions, setSuggestedQuestions] = useState([])
  const [loadingQuestions, setLoadingQuestions] = useState(true)
  const [visitedQuestionIDsArray, setVisitedQuestionIDsArray] = useState([])
  const [currentQuestion, setCurrentQuestion] = useState({})
  const [suggestedQuestion, setSuggestedQuestion] = useState({})
  // console.log(quizQuestions, QuizSubjectid, selectedSection, selectedSubject)
  // console.log(currentQuestion)

  const { quizDetails, setQuizDetails, updateQuizProperty } = useQuizDetails();
  const { questions, subjectURL, sectionURL, attemptedQuestionIds } = quizDetails || {};
  const uniqueUserId = localStorage.getItem('uniqueUserId');
  console.log(questions);



  useEffect(() => {
    let notVisitedQuestions = questions.filter((question) => !attemptedQuestionIds.includes(question.Sn))
    setSuggestedQuestions(notVisitedQuestions.slice(0, 5));
  }, [attemptedQuestionIds])



  const renderhtml = (paragraphs) => {
    return <i className='renderhtml' dangerouslySetInnerHTML={{ __html: paragraphs }} />;
  };
  const handleSelectSuggestedQuestion = (sn) => {
    const index = questions.findIndex(question => question.Sn == sn);
    updateQuizProperty('currentQuestionIndex', index);

  }
  return (
    <>
      <div className='container-fluid p-3 pt-0'>
        <div className='row'>
          <div className='col-lg-12' >
            <Questionpreview2 />
            <p className='py-2 text-info'><b>More Questions Selected Just For You. Attempt Now!</b></p>

            <div className='mt-3 card questionpreviewpadding' style={{ boxShadow: "0 3px 10px rgba(0,0,0,0.3)" }} >
              {suggestedQuestions.map((question, index) => (
                <p onClick={() => handleSelectSuggestedQuestion(question.Sn)} key={index} className='p-3 suggestedquestion' style={{ backgroundColor: "#db65650b" }}><>{index + 1}. {renderhtml(question.Question)}</></p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
  // }
}

export default QuestionLayout2