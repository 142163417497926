import React, { useState, useEffect } from 'react';
import './CategoryButton.css';
import { BASE_URL } from '../../insightConfig/insightConfig';
import axios from 'axios';

function CategoryButtons({ setCategoryId, module }) {
    // Manage All button State
    const [activeButton, setActiveButton] = useState(0); //Track active button
    const [categories, setCategories] = useState([])




    useEffect(() => {
        if (module == 'news') {
            axios.get(BASE_URL + `/app/getNewsCategories`).then((response) => {
                setCategories(response.data.data);
                setCategoryId(response.data.data[0].Sn)
                console.log(response)
            }).catch((error) => {
                console.log(error)
            })
        } else {
            axios.get(BASE_URL + `/app/getBlogCategories`).then((response) => {
                setCategories(response.data.data);
                setCategoryId(response.data.data[0].Sn)
            }).catch((error) => {
                console.log(error)
            })
        }

    }, []);

    const handleClick = (index, categoryId) => {
        console.log(index, categoryId)
        setActiveButton(index)
        setCategoryId(categoryId)
    }



    return (
        <>
            <div className='buttontab-background'>
                <div className='p-3'>
                    <div className="buttontab-Container">
                        {categories.map((obj, index) => (
                            <button
                                key={index}
                                className={`btn btn-margin ${activeButton === index ? 'activebuttontab' : "bgtab-white"}`}
                                onClick={() => handleClick(index, obj.Sn)}
                                type="button">{obj.Category}</button>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default CategoryButtons;



// {
//     componentName === 'Prelims' && componentName !== 'WithPrelims' && componentName !== 'WithPrelimsQuit' &&
//     <div className='p-3'>
//         <div className="buttontab-Container">
//             {buttonNames.map((name, index) => (
//                 <>
//                     <button
//                         key={index}
//                         className={`btn btn-prelims-margin activebuttontabmcq`}
//                         type="button">PT MCQs</button>
//                     <button
//                         key={index}
//                         className={`btn btn-prelims-margin ${activeButton === ContentToShow ? 'activebuttontabmcq' : 'bgtab-mcq'}`}
//                         onClick={
//                             () => {
//                                 handleButtonClick(name)
//                                 switch (name) {
//                                     case 'In News':
//                                         // Handle In News accordingly
//                                         setContentToShow('In News');
//                                         break;
//                                     case 'Editorial':
//                                         // Handle Editorial accordingly
//                                         setContentToShow('Editorial');
//                                         break;
//                                     case 'Yojana':
//                                         // Handle Yojana accordingly
//                                         setContentToShow('Yojana');
//                                         break;
//                                     case 'Big Picture':
//                                         // Handle Big Picture accordingly
//                                         setContentToShow('Big Picture');
//                                         break;
//                                     case 'Insight Radio':
//                                         // Handle Insight Radio accordingly
//                                         setContentToShow('Insight Radio');
//                                         break;
//                                     case 'Blogs':
//                                         // Handle Blogs accordingly
//                                         setContentToShow('Blogs');
//                                         break;
//                                     case "Today's Special":
//                                         // Handle Today's Special accordingly
//                                         setContentToShow("Today's Special");
//                                         break;
//                                     case "PT MCQs":
//                                         // Handle PT MCQs accordingly
//                                         setContentToShow("PT MCQs");
//                                         break;
//                                     case "START":
//                                         // Handle START accordingly
//                                         setContentToShow("START");
//                                         showAlert()
//                                         break;
//                                     default:
//                                         break;
//                                 }
//                             }
//                         }
//                         type="button">{name}</button>
//                 </>
//             ))}
//         </div>
//     </div>
// }
// {
//     componentName === 'WithPrelims' && componentName !== 'Prelims' && componentName !== 'WithPrelimsQuit' &&
//     <div className='p-3'>
//         <div className="buttontab-Container">
//             <button
//                 className={`btn btn-prelims-margin activebuttontabmcq`}
//                 type="button">PT MCQs</button>
//             {buttonNames.map((name, index) => (
//                 <>
//                     <button
//                         key={index}
//                         className={`btn btn-prelims-margin ${activeButton === ContentToShow ? 'bg-Question-white' : 'bg-Question-white'}`}
//                         onClick={
//                             () => {
//                                 handleButtonClick(name)
//                                 switch (name) {
//                                     case 'In News':
//                                         // Handle In News accordingly
//                                         setContentToShow('In News');
//                                         break;
//                                     case 'Editorial':
//                                         // Handle Editorial accordingly
//                                         setContentToShow('Editorial');
//                                         break;
//                                     case 'Yojana':
//                                         // Handle Yojana accordingly
//                                         setContentToShow('Yojana');
//                                         break;
//                                     case 'Big Picture':
//                                         // Handle Big Picture accordingly
//                                         setContentToShow('Big Picture');
//                                         break;
//                                     case 'Insight Radio':
//                                         // Handle Insight Radio accordingly
//                                         setContentToShow('Insight Radio');
//                                         break;
//                                     case 'Blogs':
//                                         // Handle Blogs accordingly
//                                         setContentToShow('Blogs');
//                                         break;
//                                     case "Today's Special":
//                                         // Handle Today's Special accordingly
//                                         setContentToShow("Today's Special");
//                                         break;
//                                     case "PT MCQs":
//                                         // Handle PT MCQs accordingly
//                                         setContentToShow("PT MCQs");
//                                         break;
//                                     case "REATTEMPT":
//                                         // Handle START accordingly
//                                         setContentToShow("REATTEMPT");
//                                         showAlert()
//                                         handlelocal()
//                                         break;
//                                     case "CONTINUE":
//                                         // Handle START accordingly
//                                         setContentToShow("CONTINUE");
//                                         showAlert()
//                                         break;
//                                     case "VIEW REPORT":
//                                         // Handle START accordingly
//                                         setContentToShow("VIEW REPORT");
//                                         navigate('/CourseDescreption/CourseResult')
//                                         break;
//                                     default:
//                                         break;
//                                 }
//                             }
//                         }
//                         type="button">{name}</button>
//                 </>
//             ))}
//         </div>
//     </div>
// }
// {
//     componentName === 'WithPrelimsQuit' && componentName !== 'Prelims' && componentName !== 'WithPrelims' &&
//     <div className='p-3'>
//         <div className="buttontab-Container">
//             <button
//                 className={`btn btn-prelims-margin activebuttontabmcq`}
//                 type="button">PT MCQs</button>
//             {buttonNames.map((name, index) => (
//                 <>
//                     <button
//                         key={index}
//                         className={`btn btn-prelims-margin ${activeButton === ContentToShow ? 'bg-Question-white' : 'bg-Question-white'}`}
//                         onClick={
//                             () => {
//                                 handleButtonClick(name)
//                                 switch (name) {
//                                     case 'In News':
//                                         // Handle In News accordingly
//                                         setContentToShow('In News');
//                                         break;
//                                     case 'Editorial':
//                                         // Handle Editorial accordingly
//                                         setContentToShow('Editorial');
//                                         break;
//                                     case 'Yojana':
//                                         // Handle Yojana accordingly
//                                         setContentToShow('Yojana');
//                                         break;
//                                     case 'Big Picture':
//                                         // Handle Big Picture accordingly
//                                         setContentToShow('Big Picture');
//                                         break;
//                                     case 'Insight Radio':
//                                         // Handle Insight Radio accordingly
//                                         setContentToShow('Insight Radio');
//                                         break;
//                                     case 'Blogs':
//                                         // Handle Blogs accordingly
//                                         setContentToShow('Blogs');
//                                         break;
//                                     case "Today's Special":
//                                         // Handle Today's Special accordingly
//                                         setContentToShow("Today's Special");
//                                         break;
//                                     case "PT MCQs":
//                                         // Handle PT MCQs accordingly
//                                         setContentToShow("PT MCQs");
//                                         break;
//                                     case "REATTEMPT":
//                                         // Handle START accordingly
//                                         setContentToShow("REATTEMPT");
//                                         showAlert()
//                                         handlelocal()
//                                         break;
//                                     case "CONTINUE":
//                                         // Handle START accordingly
//                                         setContentToShow("CONTINUE");
//                                         showAlert()
//                                         break;
//                                     case "VIEW REPORT":
//                                         // Handle START accordingly
//                                         setContentToShow("VIEW REPORT");
//                                         navigate('/CourseDescreption/CourseResult')
//                                         break;
//                                     default:
//                                         break;
//                                 }
//                             }
//                         }
//                         type="button">{name}</button>
//                 </>
//             ))}
//         </div>
//     </div>
// }
// {
//     componentName === 'Cuet' &&
//     <div className='p-3'>
//         <div className="buttoncuettab-Container">
//             {buttonNames.map((name, index) => (
//                 <button
//                     key={index}
//                     className={`btn btncuet-margin ${activeButton === name.toLowerCase().replace(/\s+/g, '') ? 'activecuetbuttontab' : "bgcuettab-white"}`}
//                     onClick={
//                         () => {
//                             handleButtonClick(name)
//                             switch (name) {
//                                 case 'In News':
//                                     // Handle In News accordingly
//                                     setContentToShow('In News');
//                                     break;
//                                 case 'Editorial':
//                                     // Handle Editorial accordingly
//                                     setContentToShow('Editorial');
//                                     break;
//                                 case 'Yojana':
//                                     // Handle Yojana accordingly
//                                     setContentToShow('Yojana');
//                                     break;
//                                 case 'Big Picture':
//                                     // Handle Big Picture accordingly
//                                     setContentToShow('Big Picture');
//                                     break;
//                                 case 'Insight Radio':
//                                     // Handle Insight Radio accordingly
//                                     setContentToShow('Insight Radio');
//                                     break;
//                                 case 'Blogs':
//                                     // Handle Blogs accordingly
//                                     setContentToShow('Blogs');
//                                     break;
//                                 case "Today's Special":
//                                     // Handle Today's Special accordingly
//                                     setContentToShow("Today's Special");
//                                     break;
//                                 case "PT MCQs":
//                                     // Handle PT MCQs accordingly
//                                     setContentToShow("PT MCQs");
//                                     break;
//                                 case "START":
//                                     // Handle START accordingly
//                                     setContentToShow("START");
//                                     break;
//                                 case "CUET":
//                                     // Handle START accordingly
//                                     setContentToShow("CUET");
//                                     // setcuetlink("#")
//                                     break;
//                                 case "CUET SECTION – IA":
//                                     // Handle START accordingly
//                                     setContentToShow("CUET SECTION – IA");
//                                     // setcuetlink("/cuet/prelims-test-series-for-upsc")
//                                     navigate('/cuet/prelims-test-series-for-upsc')
//                                     break;
//                                 case "CUET SECTION – II":
//                                     // Handle START accordingly
//                                     setContentToShow("CUET SECTION – II");
//                                     // setcuetlink("/cuet/Pre-Cum-Mains-Test-Series")
//                                     navigate('/cuet/Pre-Cum-Mains-Test-Series')
//                                     break;
//                                 case "CUET SECTION – III":
//                                     // Handle START accordingly
//                                     setContentToShow("CUET SECTION – III");
//                                     // setcuetlink("#")
//                                     break;
//                                 case "RESOURCES":
//                                     // Handle START accordingly
//                                     setContentToShow("CUET SECTION – III");
//                                     // setcuetlink("#")
//                                     break;
//                                 case "PRACTICE QUIZ":
//                                     // Handle START accordingly
//                                     setContentToShow("PRACTICE QUIZ");
//                                     // setcuetlink("#")
//                                     break;
//                                 default:
//                                     break;
//                             }
//                         }
//                     }
//                     type="button">{name}</button>
//             ))}
//         </div>
//     </div>
// }
// {
//     componentName === 'Blog' &&
//     <div className='pt-3'>
//         <div className="buttoncuettab-Container">
//             {buttonNames.map((name, index) => (
//                 <button
//                     key={index}
//                     className={`btn btncuet-margin ${activeButton === name.toLowerCase().replace(/\s+/g, '') ? 'activeblogbuttontab' : "bgblogtab-white"}`}
//                     onClick={
//                         () => {
//                             handleButtonClick(name)
//                             switch (name) {
//                                 case 'In News':
//                                     // Handle In News accordingly
//                                     setContentToShow('In News');
//                                     break;
//                                 case 'Editorial':
//                                     // Handle Editorial accordingly
//                                     setContentToShow('Editorial');
//                                     break;
//                                 case 'Yojana':
//                                     // Handle Yojana accordingly
//                                     setContentToShow('Yojana');
//                                     break;
//                                 case 'Big Picture':
//                                     // Handle Big Picture accordingly
//                                     setContentToShow('Big Picture');
//                                     break;
//                                 case 'Insight Radio':
//                                     // Handle Insight Radio accordingly
//                                     setContentToShow('Insight Radio');
//                                     break;
//                                 case 'Blogs':
//                                     // Handle Blogs accordingly
//                                     setContentToShow('Blogs');
//                                     break;
//                                 case "Today's Special":
//                                     // Handle Today's Special accordingly
//                                     setContentToShow("Today's Special");
//                                     break;
//                                 case "PT MCQs":
//                                     // Handle PT MCQs accordingly
//                                     setContentToShow("PT MCQs");
//                                     break;
//                                 case "START":
//                                     // Handle START accordingly
//                                     setContentToShow("START");
//                                     break;
//                                 default:
//                                     break;
//                             }
//                         }
//                     }
//                     type="button">{name}</button>
//             ))}
//         </div>
//     </div>
// }