import React, { useEffect, useState } from 'react';
import "./Current.css";
import allCoughtUpImage from './cought-2.jpg'
import Buttontabs from '../Buttontabs/Buttontabs';
import InfiniteScroll from 'react-infinite-scroll-component'; // Importing InfiniteScroll
import Current1 from '../CurrentAffairsComponent/Current1';
import Pagelayout from '../EffectonLoad/pagelayout';
import { Link } from 'react-router-dom';
import { BASE_URL } from '../../insightConfig/insightConfig';
import { fetchCurrentaffair1, fetchCurrentaffair2 } from '../DynamicCourse/api';
import CategoryButtons from './CategoryButtons';
import axios from 'axios';

function Current() {
    const [categoryId, setCategoryId] = useState(null);
    const [news, setNews] = useState([]);


    const [hasMore, setHasMore] = useState(false);
    const [isloading, setisloading] = useState(false);
    const [isfilterapply, setisfilterapply] = useState(false);
    const [isAllCoughtUp, setIsAllCoughtUp] = useState(false);
    const [fetching, setFetching] = useState(false);



    const [newsubcateogry, setNewsubcateogry] = useState([])
    const [selectedTag, setSelectedTag] = useState(null);
    const [startDate, setStartDate] = useState("2020-01-19")
    const [endDate, setEndDate] = useState(() => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    })

    // console.log(categoryId, selectedTag, startDate, endDate)
    console.log(isloading, isfilterapply)

    useEffect(() => {
        const fetchData = async () => {
            try {
                setisloading(true)
                setisfilterapply(false)
                let response = await axios.get(BASE_URL + `/app/getNewsByCategories/${categoryId}`)
                console.log(response)
                setNews(response.data.data)
                setisloading(false)
            } catch (error) {
                console.error('Error fetching data:', error);
                setisloading(false)
            }
        };
        fetchData();
    }, [categoryId]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                let response = await axios.get(BASE_URL + `/news-subcategory`)
                let categories = response.data.data;
                if (categories.length) setSelectedTag(categories[0])
                categories.unshift('All Tags')
                setNewsubcateogry(categories)
                setSelectedTag(categories[0])
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [])

    const fetchMoreData = async () => {
        try {
            if (isfilterapply) {
                let response = await axios.get(`${BASE_URL}/current-affairs-view-more/${categoryId}/${selectedTag}/${startDate}/${endDate}/${news.length}`)
                let newData = response.data.data;
                if (newData.length < 5) {
                    setIsAllCoughtUp(true)
                }
                setNews(prevNews => [...prevNews, ...newData]);
                setisloading(false);
            } else {
                let response = await axios.get(`${BASE_URL}/current-affairs/${categoryId}/${news.length}`)
                let newData = response.data.data;
                if (newData.length < 5) {
                    setIsAllCoughtUp(true)
                }
                setNews(prevNews => [...prevNews, ...newData]);
                setisloading(false);

            }
        } catch (error) {
            console.error('Error fetching more data:', error);
            setisloading(false);
        }
    };


    const handleViewMoreClick = () => {
        setisloading(true);
        setFetching(true)
        fetchMoreData();
        setHasMore(fetching)
    };

    const handleStopViewMoreClick = () => {
        setFetching(false)
        setHasMore(fetching)
    };

    return (
        <>
            <div className='CurrentAffairs-background'>
                <div className='current-main-box'>
                    <h1>DAILY CURRENT AFFAIRS »»</h1>
                    <CategoryButtons module='news' setCategoryId={setCategoryId} />
                </div>
            </div>
            {/* Display Information Section Start */}
            <InfiniteScroll
                dataLength={news.length} // This is important to prevent infinite loop when the content is updated
                next={handleStopViewMoreClick}
                hasMore={hasMore}
                loader={<>
                    <div className='Civil-section blog1-section'>
                        <div className='civil-row'>
                            <div className='col-lg-8 col-md-9 col-sm-12'>
                                {isloading && <Pagelayout />}
                            </div>
                        </div>
                    </div>
                </>}
            >
                {/* Display Information Section Start */}
                <Current1 news={news} categoryId={categoryId} setNews={setNews} startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} selectedTag={selectedTag} setSelectedTag={setSelectedTag} newsubcateogry={newsubcateogry}

                    isloading={isloading} isfilterapply={isfilterapply} setisfilterapply={setisfilterapply} />
            </InfiniteScroll>
            <div className='Civil-section blog1-section'>
                <div className='civil-row white view-width1 d-flex justify-content-evenly blog-margin-bottom '>
                    {isAllCoughtUp
                        ?
                        <img className='w-30' /* style={{minWidth:"30%", maxWidth:"40%" ,height:"20%"}} */ src={allCoughtUpImage} alt="" />
                        :
                        news.length ? <button className='list-group-item p-2 px-4 rounded-pill text-white list-background' onClick={handleViewMoreClick}>Click to View More</button> : null
                    }

                </div>
            </div>
        </>
    );
}

export default Current;
