import React, { useEffect, useState } from 'react';
import "./Setting.css";
import { FaUser } from "react-icons/fa";
import { IoCalendarOutline } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { FaUserCheck } from "react-icons/fa";
import { IoCloudUpload } from "react-icons/io5";
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../insightConfig/insightConfig';
import axios from 'axios';
import { RiDownload2Fill } from "react-icons/ri";
import { Spinner } from 'react-bootstrap';
import { getCookie } from '../../commonServices/cookieService';

function Setting({ userDtails }) {
    const authtoken = localStorage.getItem('authtoken');
    const alert = useAlert();
    const navigate = useNavigate();
    const [FirstName, setFirstName] = useState(userDtails.FirstName);
    const [MobileNo, setMobileNo] = useState(userDtails.MobileNo);
    const [Address, setAddress] = useState(userDtails.Address);
    const [date, setDate] = useState(userDtails.Date);
    const [Month, setMonth] = useState(userDtails.Month);
    const [Year, setYear] = useState(userDtails.Year);
    const [error, setError] = useState('');
    const [academicQFile, setAcademicQFile] = useState(null);
    const [addressProofFile, setAddressProofFile] = useState(null);
    const [academicQFilePath, setAcademicQFilePath] = useState(userDtails?.AcedemicQualification);
    const [addressProofFilePath, setAddressProofFilePath] = useState(userDtails?.AddressProof);
    const [fileUploading1, setFileUploading1] = useState(false);
    const [fileUploading2, setFileUploading2] = useState(false);

    // Function to format the date of birth
    const formatDateOfBirth = () => {
        const formattedDate = String(date).padStart(2, '0');
        const formattedMonth = String(Month + 1).padStart(2, '0'); // Adding 1 to month because months are zero-indexed
        return `${Year}-${formattedMonth}-${formattedDate}`;
    };

    const handleUpdateUser = async (e) => {
        e.preventDefault();
        if (!FirstName.trim()) {
            alert.show('Please Enter Name');
            setError('Please fill all fields');
            return;
        } else if (!MobileNo.trim()) {
            alert.show('Please Enter Mobile Number');
            setError('Please fill all fields');
            return;
        }

        try {
            const response = await axios.post(`${BASE_URL}/update-student`, {
                FirstName, MobileNo, Address, "Date": date, Month, Year, academicQFilePath, addressProofFilePath
            })
            if (response.data.statusCode !== 200) {
                throw new Error('Update User failed');
            }
            // Reset form fields and state
            setFirstName(userDtails.FirstName)
            setMobileNo(userDtails.MobileNo);
            setAddress(userDtails.Address);
            setDate(userDtails.Date);
            setMonth(userDtails.Month);
            setYear(userDtails.Year);
            // Show login success message
            alert.show('Update User successfully');
            navigate("/login");
        } catch (error) {
            setError('Error while updating the use details');
            alert.show('Error while updating the use details')
        }
    };

    useEffect(() => {
        const formData = new FormData();
        if (academicQFile) {
            //upload image on server 
            setFileUploading1(true)
            formData.append('uploadedFile', academicQFile, academicQFile.name);
            axios.post(BASE_URL + '/uploadFile', formData)
                .then((response) => {
                    if (response.data.statusCode == 201) {
                        setAcademicQFilePath(response.data.imagePath)
                        setAcademicQFile(null)
                        setAddressProofFile(null)
                        setTimeout(() => {
                            alert.show('File uploaded Successfully')
                            setFileUploading1(false)
                        }, 1500)
                    }
                })
                .catch(error => {
                    console.error(error.response.data.message);
                });
        } else if (addressProofFile) {
            setFileUploading2(true)
            formData.append('uploadedFile', addressProofFile, addressProofFile.name);
            axios.post(BASE_URL + '/uploadFile', formData)
                .then((response) => {
                    if (response.data.statusCode == 201) {
                        setAddressProofFilePath(response.data.imagePath)
                        setAcademicQFile(null)
                        setAddressProofFile(null)

                        setTimeout(() => {
                            alert.show('File uploaded Successfully')
                            setFileUploading2(false)
                        }, 1500)

                    }
                })
                .catch(error => {
                    console.error(error.response.data.message);
                });
        }
    }, [academicQFile, addressProofFile])

    const selectedFileHandler = (fileName, file) => {
        if (fileName == 'AcademicFile') {
            setAcademicQFile(file)
            setAddressProofFile(null)
        } else {
            setAddressProofFile(file)
            setAcademicQFile(null)
        }

    }


    return (
        <>
            <div className='Setting-background'>
                <form onSubmit={handleUpdateUser}>
                    <div className='row'>
                        <div className="col-lg-6 col-sm-12">
                            <label className="form-label fw-bold"><FaUser className='me-2' />Full Name <span className='text-danger'>*</span></label>
                            <input type="text" className="form-control" value={FirstName} onChange={(e) => setFirstName(e.target.value)} />
                        </div>
                        <div className="col-lg-6 col-sm-12">
                            <label className="form-label fw-bold"><IoCalendarOutline className='me-2' /> Date Of Birth <span className='text-danger'>*</span></label>
                            <input type="date" className="form-control" value={formatDateOfBirth()} onChange={(e) => {
                                const selectedDate = new Date(e.target.value);
                                setDate(selectedDate.getDate());
                                setMonth(selectedDate.getMonth());
                                setYear(selectedDate.getFullYear());
                            }} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-lg-6 col-sm-12">
                            <label className="form-label fw-bold"><MdEmail className='me-2' />Email <span className='text-danger'>*</span></label>
                            <input type="email" className="form-control" value={userDtails.EmailId} readOnly />
                        </div>
                        <div className="col-lg-6 col-sm-12">
                            <label className="form-label fw-bold"><FaPhoneAlt className='me-2' />Mobile <span className='text-danger'>*</span></label>
                            <input type="number" className="form-control" id="exampleInputPassword1" value={MobileNo} onChange={(e) => setMobileNo(e.target.value)} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-lg-12 col-sm-12">
                            <label className="form-label fw-bold"><FaLocationDot className='me-2' />Permanent Address</label>
                            <textarea className="form-control" rows="3" value={Address} onChange={(e) => setAddress(e.target.value)}></textarea>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-lg-6 col-sm-12">
                            <label htmlFor='file1' className="form-label fw-bold"><FaUserCheck className='me-2' />Academic Qualification</label>
                            <label for="file1" className="custom-input w-75"> <IoCloudUpload className='upload-icon1 me-1' size={23} />Upload File</label>
                            <input id="file1" style={{ display: "none" }} type="file" accept=".pdf" onChange={(event) => selectedFileHandler('AcademicFile', event.target.files[0])} />
                            {fileUploading1 ? <Spinner className='' variant="info" /> : (academicQFilePath || userDtails.AcedemicQualification) && <RiDownload2Fill style={{ cursor: "pointer" }} onClick={() => window.open(academicQFilePath || userDtails?.AcedemicQualification, '_blank')} className='ms-2 bg-gray' color='green' size={40} />}

                        </div>
                        <div className="col-lg-6 col-sm-12">
                            <label htmlFor='file2' className="form-label fw-bold"><FaUserCheck className='me-2' />Address & ID Proof</label>
                            <label for="file2" className="custom-input w-75"> <IoCloudUpload className='upload-icon1 me-1' size={23} />Upload File</label>
                            <input id="file2" style={{ display: "none" }} accept=".pdf" type="file" onChange={(event) => selectedFileHandler('AddressProof', event.target.files[0])} />

                            {fileUploading2 ? <Spinner className='' variant="info" /> : (addressProofFilePath || userDtails?.AddressProof) && <RiDownload2Fill style={{ cursor: "pointer" }} onClick={() => window.open(addressProofFilePath || userDtails?.AddressProof, '_blank')} className='ms-2 bg-gray' color='green' size={40} />}


                        </div>
                        {/* <div className="col-lg-6 col-sm-12">
                            <label htmlFor='file2' className="form-label fw-bold"><FaUserCheck className='me-2' />Address & ID Proof</label>
                            <IoCloudUpload className='upload-icon2' /><input id='file2' type="file" className="" />
                        </div> */}
                    </div>
                    <div className='d-flex justify-content-center align-items-center'>
                        <button type="submit" className="btn btn-save">Save</button>
                    </div>
                </form>
                {/* Error message if login fails */}
                {error && <p>{error}</p>}
            </div>
        </>
    )
}

export default Setting;