import React, { useEffect, useRef, useState } from 'react';
import "../../jsx/TestStartComponent/teststart.css";
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Content from '../AllPageContent/AllPageContent';
import subjimg1 from "../../Image/sub1.png";
import subjimg2 from "../../Image/sub2.png";
import subjimg3 from "../../Image/sub3.png";
import subjimg4 from "../../Image/sub4.png";
import subjimg5 from "../../Image/sub5.png";
import { saveAs } from 'file-saver';
import { useAlert } from 'react-alert';
import axios from 'axios';
import { Button, Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import YoutubeModal from '../YoutubeModal/YoutubeModal';
import { BASE_URL } from '../../insightConfig/insightConfig';
import $ from 'jquery';
import swal from 'sweetalert';
import { SlArrowLeft } from 'react-icons/sl';
import { Spinner } from 'react-bootstrap';
import { RiDownload2Fill } from 'react-icons/ri';
import { HiDownload } from "react-icons/hi";
import { getBookTestData, rules } from './helper';

function StartBookTest() {
    const { courseId, packageId } = useParams();
    const storedAuthToken = localStorage.getItem('authtoken');
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadedFilePath, setUploadedFilePath] = useState('')
    const [isOpen, setIsOpen] = useState(false);
    const [evaluatedFile, setEvaluatedFile] = useState(null)
    const [answerLink, setAnswerLink] = useState(null)
    const [attemptCount, setAttemptCount] = useState(null)
    const [laodAnswer, setLoadAnswer] = useState(false)
    const [fileUploading, setFileUploading] = useState(false);
    const alert = useAlert();
    const fileInputRef = useRef(null);
    const navigate = useNavigate()



    const location = useLocation();
    const [testData, setTestData] = useState(location?.state?.testData || {});
    console.log(testData)

    useEffect(() => {
        const fetchData = async () => {
            const testData = await getBookTestData(packageId)
            setTestData(testData)

        }
        fetchData();
    }, [location]);





    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);

    };
    const handleUploadFile = () => {
        fileInputRef.current.click(); // Trigger click event on file input
    };
    useEffect(() => {
        if (selectedFile) {
            setFileUploading(true)
            //upload image on server
            const formData = new FormData();
            formData.append('uploadedFile', selectedFile, selectedFile.name);
            axios.post(BASE_URL + '/uploadFile', formData)
                .then((response) => {
                    if (response.data.statusCode == 201) {
                        setUploadedFilePath(response.data.imagePath)
                    }
                })
                .catch(error => {
                    console.log(error.response.data.message);
                });
        }
    }, [selectedFile])
    console.log(attemptCount)
    useEffect(() => {
        if (uploadedFilePath) {
            axios.post(BASE_URL + '/upload-subjecive-answer', { packageId: testData?.PackageID, studentEmailId: storedAuthToken, answerPdfLink: uploadedFilePath, attemptCount: attemptCount ?? 0 })
                .then((response) => {
                    if (response.data.statusCode == 200) {
                        setLoadAnswer(!laodAnswer)
                        setFileUploading(false)
                        swal(response.data.message, {
                            icon: "success",
                            buttons: {
                                confirm: {
                                    text: "OK",
                                    value: true,
                                    visible: true,
                                    closeModal: true
                                }
                            }
                        })
                    }

                })
                .catch(error => {
                    swal("Oops", "Something went wrong! Please Try Again", { icon: "error" })
                    console.log(error?.response?.data.message);
                });
        }
    }, [uploadedFilePath])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${BASE_URL}/get-answer-and-evaluated-copy/${testData?.PackageID}`);
                const data = await response.json();
                setEvaluatedFile(data?.EvaluateCopyLink[0]?.EvaluateCopyLink);
                setAnswerLink(data?.EvaluateCopyLink[0]?.AnswerLink);
                setAttemptCount(data?.EvaluateCopyLink[0]?.AttemptCount);
                console.log(data)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        if (testData?.Type !== "MCQ") fetchData();

    }, [testData?.PackageID, laodAnswer]);
    console.log(testData)













    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };










    const viewReportJSX = <Link to={`/testseries/finalresult`} state={{ testData }} type="button" className="btn bg-Question-white btn-md ms-md-3 col-md-5">VIEW REPORT</Link>

    const reAttemptJSX = <Link to={`/testseriesstart/${testData?.PackageID}`} state={{ testData: { ...testData, reAttempt: true } }} type="button" className="btn bg-Question-white btn-md col-md-5">REATTEMPT</Link>

    const continueJSX = <Link to={`/testseriesstart/${testData?.PackageID}`} state={{ testData }} type="button" className="btn bg-Question-white btn-md col-md-5">CONTINUE</Link>

    const startJSX = <Link to={`/books/test-series/attemt/${courseId}/${packageId}`} state={{ testData: { ...testData, reAttempt: false } }} type="button" className="btn bg-Question-white btn-md col-md-5">START</Link>



    const selectTestStartButtons = () => {
        const allTestsReportsArray = JSON.parse(localStorage.getItem("BookTestSeriesReportArray"));
        const currentTest = allTestsReportsArray?.find((test) => test.packageId == packageId);

        if (currentTest) {
            if (testData?.Status == 'Complete') {
                return (
                    <div className='d-md-flex justify-content-start'>
                        {continueJSX}
                        {viewReportJSX}
                    </div>
                )
            } else {
                return (
                    <div className='d-md-flex justify-content-start'>
                        {continueJSX}
                    </div>
                )
            }

        } else {
            if (testData?.Status == 'Complete') {
                if (testData?.AttemptFinished == 0) {
                    return (
                        <div className='d-md-flex justify-content-start'>
                            {reAttemptJSX}
                            {viewReportJSX}
                        </div>
                    )
                } else {
                    return (
                        <div className='d-md-flex justify-content-start'>
                            {viewReportJSX}
                        </div>
                    )
                }
            } else {
                return (
                    <div className='d-md-flex justify-content-start'>
                        {startJSX}
                    </div>
                )
            }
        }

    }
    const currentTestSeriesTitleURL = JSON.parse(localStorage.getItem("CurrentTestSeriesTitleURL"));



    const setButtonName = () => {
        if (fileUploading) {
            return <Spinner size='sm' className='fs-5 m-0' variant="light" />
        } else if (answerLink) {
            return 'UPLOADED'
        } else {
            return 'UPLOAD'
        }


        // answerLink ? 'UPLOADED' : 'UPLOAD'
    }
    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-lg-3 test-background p-3 pt-2'>
                        <button onClick={() => navigate('/testseries/' + currentTestSeriesTitleURL)} className='btn btn-sm btn-info mb-2 mt-0'><SlArrowLeft /> Back</button>
                        <div className='d-grid gap-3'>
                            <button type="button" className="btn bg-darkblue btn-md py-2">
                                {testData?.type === "MCQ" ? <>PTS-{testData?.accessCode}</> : <>MTS-{testData?.accessCode}</>} : {testData?.title}
                                <hr className='my-1' />
                                {testData?.questionCount} Ques | {testData?.duration} min | {testData?.totalMarks} marks
                            </button>
                            <button type="button" className="btn bg-darkorange btn-md py-3">OVERVIEW</button>
                            {testData?.type === 'MCQ' && <button type="button" className="btn bg-darkorange btn-md py-3" onClick={() => window.open(testData?.modelAnswerPDF, '_blank')}>EXPLANATION</button>}
                            <button disabled={!testData.videoURL} type="button" onClick={openModal} className="btn bg-darkgreen text-white btn-md p-3">VIDEO DISCUSSION</button>
                        </div>
                    </div>
                    <div className='col-lg-9 teststart-padding'>
                        <div className='p-3 mb-4 rounded-2' style={{ background: "#fffde4", boxShadow: "0 10px 15px rgba(0,0,0,0.3)" }}>
                            {testData?.type === "MCQ" ? <Content paragraphs={rules} /> : <Content paragraphs={rules} />}
                        </div>
                        {testData?.type === 'MCQ' ? <><div className='d-grid w-50 gap-3'>
                            {selectTestStartButtons()}
                        </div></> :
                            <div className='container-fluid'>
                                <div className='Subjective-container' style={{ overflow: "scroll" }}>
                                    <div className='sub1 p-3'>
                                        <div className='d-flex flex-column align-items-center'>
                                            <img src={subjimg1} alt='...' />
                                            <p className='py-1'>Question Paper</p>
                                            <button disabled={!testData?.QuestionPDF} type="button" onClick={() => window.open(testData?.QuestionPDF, '_blank')} className="btn bg-Question-white px-5 btn-sm">DOWNLOAD</button>
                                        </div>
                                    </div>
                                    <div className='sub1 p-3'>
                                        <div className='d-flex flex-column align-items-center'>
                                            <img src={subjimg2} alt='...' />
                                            <p className='py-1'>Answer Booklet</p>
                                            <button disabled={!testData?.AnswerBookletPDF} type="button" onClick={() => window.open(testData?.AnswerBookletPDF, '_blank')} className="btn bg-Question-white px-5 btn-sm">DOWNLOAD</button>
                                        </div>
                                    </div>
                                    <div className='sub1 p-3'>
                                        <div className='d-flex flex-column align-items-center'>
                                            <img src={subjimg3} alt='...' />
                                            <p className='py-1'>Your Answer {answerLink && <RiDownload2Fill style={{ cursor: "pointer" }} onClick={() => window.open(answerLink, '_blank')} className='ms-2 ' color='#e52626' size={20} />}</p>
                                            <input
                                                type="file"
                                                ref={fileInputRef}
                                                style={{ display: 'none' }}
                                                accept='.pdf'
                                                onChange={handleFileChange}
                                                className='form-control' />
                                            <button disabled={attemptCount && attemptCount >= testData?.Attempt} type="button" onClick={handleUploadFile} className="btn bg-Question-white px-5 btn-sm">{setButtonName()}</button>
                                        </div>
                                    </div>
                                    <div className='sub1 p-3'>
                                        <div className='d-flex flex-column align-items-center'>
                                            <img src={subjimg5} alt='...' />
                                            <p className='py-1'>Model Answer</p>
                                            <button disabled={!testData?.ModelAnswerPDF} type="button" onClick={() => window.open(testData?.ModelAnswerPDF, '_blank')} className="btn bg-Question-white px-5 btn-sm">DOWNLOAD</button>
                                        </div>
                                    </div>
                                    {/* <div className='sub1 p-3'>
                                        <div className='d-flex flex-column align-items-center'>
                                            <img src={subjimg3} alt='...' />
                                            <p className='py-1'>Your Answer</p>
                                            <div className='d-flex justidy-content-content-center align-items-center'>
                                                <button className={selectedFile ? "btn bg-Question-white px-5 py-0 btn-sm button upload" : 'btn bg-Question-white px-5 btn-sm button2'} style={{ height: "50px" }}>
                                                    <span><input style={{ display:'none' }} type="file" className="" accept='application/pdf' required name="image" id="inputGroupFile01" ref={fileInputRef} onChange={handleClick} /></span>
                                                </button>
                                            </div>
                                        </div>
                                      
                                    </div>                                    */}

                                    <div className='sub1 p-3'>
                                        <div className='d-flex flex-column align-items-center'>
                                            <img src={subjimg4} alt='...' />
                                            <p className='py-1'>Evaluated Copy</p>
                                            <button disabled={!evaluatedFile} type="button" onClick={() => window.open(evaluatedFile, '_blank')} className="btn bg-Question-white px-5 btn-sm">DOWNLOAD</button>
                                        </div>
                                    </div>

                                </div>
                            </div>}
                    </div>
                </div>
            </div>
            <Modal show={isOpen} centered className="animate__animated animate__fadeIn mobileyoutube">
                <YoutubeModal closeModal={closeModal} VideoId={testData?.VideoUrl} />
            </Modal>
        </>
    )
}

export default StartBookTest




