import React, { useEffect, useRef, useState } from 'react';
import "./QuestionLayout.css"
import Questionsummary from '../Questionsummary/Questionsummary';
import Questionpreview from '../Questionpreview/Questionpreview';
import ResultPreview from '../../ResultPreview/ResultPreview';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { finalsubmittest } from '../../DynamicCourse/api';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { RiErrorWarningLine } from "react-icons/ri";
import swal from "sweetalert";
import { BASE_URL } from '../../../insightConfig/insightConfig';
import axios from 'axios';
import { FiClock } from 'react-icons/fi';
import { FcAlarmClock } from "react-icons/fc";
import { useQuizGoingOn } from '../../Context/QuizGoingOn';
// import { toast } from 'react-toastify';

function QuestionLayoutreattempted({ lessonData, courseData }) {
  const navigate = useNavigate();


  console.log(lessonData)
  const allTestsReportsArray = JSON.parse(localStorage.getItem("LessonReportArray"));
  //raaz work 
  const [testQuestions, setTestQuestions] = useState([])
  const [selectedQuestionNumber, setSelectedQuestionNumber] = useState(1)
  const [selectedQuestion, setSelectedQuestion] = useState()
  const [answerOfCurrentQuestion, setAnswerOfCurrentQuestion] = useState('')
  const [attemptedQuestions, setAttemptedQuestions] = useState([])
  const [markForReviewQuestionsArray, setMarkForReviewQuestionsArray] = useState([])
  const [viewedQuestionsArray, setViewedQuestionsArray] = useState([])
  const [finishTest, setFinishTest] = useState(false)

//global state
  const [isQuizGoingOn, setIsQuizGoingOn] = useQuizGoingOn();
  console.log(isQuizGoingOn)

  console.log(testQuestions)


  let { packageid } = useParams()
  const storedAuthToken = localStorage.getItem('authtoken');

  //time functionality
  const [timeRemaining, setTimeRemaining] = useState(lessonData.QuizDuration * 60);
  const [timeUp, setTimeUp] = useState(false)

  useEffect(() => {
    if (testQuestions?.length === 0) {
      axios.get(`${BASE_URL}/get-lession-quetions/${lessonData?.SectionID}`).then((response) => {
        console.log(response)
        setTestQuestions(response.data.questions)
      })
    }
    const allTestsReportsArray = JSON.parse(localStorage.getItem("LessonReportArray"));
    const currentTest = allTestsReportsArray?.find((test) => test.sectionId == lessonData.SectionID);
    if (currentTest?.isTestFinished == true) {
      setFinishTest(true)
      swal(
        "Test Finished! Please submit your test.",{
          closeOnClickOutside: false, // Prevent closing when clicking outside
          closeOnEsc: false 
        }
      )
    } else if (currentTest?.remainingTime < 1) {
      setTimeUp(true)
      swal(
        "Time's up! Please submit your test.", {
        closeOnClickOutside: false, // Prevent closing when clicking outside
        closeOnEsc: false
      }
      ).then(()=>{
        finalSubmitTest();
      })
    }
    setIsQuizGoingOn(1);
    return ()=>{   
      setIsQuizGoingOn(0);
    }
  }, [])
  console.log(testQuestions)

  console.log(selectedQuestionNumber ,testQuestions.length)


  useEffect(() => {
    if (finishTest || timeUp) {
      return
    }
    const timer = setInterval(() => {
      setTimeRemaining(prevTime => {
        if (prevTime > 0) {
          const allTestsReportsArray = JSON.parse(localStorage.getItem("LessonReportArray")) || [];
          const testIndex = allTestsReportsArray.findIndex(test => test.sectionId === lessonData.SectionID);
          if (testIndex !== -1) {
            const copyOfTestDetails = [...allTestsReportsArray];
            copyOfTestDetails[testIndex].remainingTime = prevTime - 1;
            localStorage.setItem("LessonReportArray", JSON.stringify(copyOfTestDetails));
          }
          return prevTime - 1;
        } else {
          setTimeUp(true)         
          swal(
            "Time's up! Please submit your test.", {
            closeOnClickOutside: false, // Prevent closing when clicking outside
            closeOnEsc: false
          }
          ).then((yes)=>{
            if(yes){
              finalSubmitTest();
            }
          })
          clearInterval(timer);
          return 0;
        }
      });
    }, 1000);
    return () => clearInterval(timer);
  }, [lessonData.QuizDuration, lessonData.SectionID, timeRemaining]);

  const minutes = Math.floor(timeRemaining / 60);
  const seconds = timeRemaining % 60;
















  const getCurrentTime = () => {
    const currentTime = new Date();
    let hours = currentTime.getHours();
    let minutes = currentTime.getMinutes();
    let seconds = currentTime.getSeconds();
    hours = (hours < 10 ? '0' : '') + hours;
    minutes = (minutes < 10 ? '0' : '') + minutes;
    seconds = (seconds < 10 ? '0' : '') + seconds;
    return `${hours}:${minutes}:${seconds}`;
  }

 

  useEffect(() => {
    if (testQuestions && testQuestions.length > 0) {
      let intialTestResponseObj = new Array(testQuestions.length).fill(null).map((_, index) => ({
        questionNumber: index + 1,
        selectedOption: '',
        correctOption: testQuestions?.find(question => question.QNo == index + 1)?.Answer,
        markForReview: false,
        isQuestionViewed: false,
      }));

      const LessonReportArray = JSON.parse(localStorage.getItem("LessonReportArray"));


      console.log('LessonReportArray', LessonReportArray)



      if (LessonReportArray && LessonReportArray?.length > 0) {
        const testToBeContinued = LessonReportArray.find((test) => test.sectionId === lessonData.SectionID);
        setTimeRemaining(testToBeContinued?.remainingTime)
        console.log(testToBeContinued)
        if (testToBeContinued) {
          setSelectedQuestionNumber(testToBeContinued.lastQuestion);
          setAttemptedQuestions(testToBeContinued.userResponseObj?.filter((question) => question.selectedOption)?.map((question) => question.questionNumber));
        } else {
          LessonReportArray.push({
            isTestFinished: false,
            sectionId: lessonData.SectionID,
            reAttempt: lessonData.reAttempt,
            startTime: new Date(),
            lastQuestion: 1,
            remainingTime: lessonData.QuizDuration * 60,
            userResponseObj: intialTestResponseObj
          });

          localStorage.setItem("LessonReportArray", JSON.stringify(LessonReportArray));
          setSelectedQuestionNumber(1);
        }
      } else {
        const newTestDetails = [{
          isTestFinished: false,
          sectionId: lessonData.SectionID,
          reAttempt: lessonData.reAttempt ,
          startTime: new Date(),
          lastQuestion: 1,
          remainingTime: lessonData.QuizDuration * 60,
          userResponseObj: intialTestResponseObj,
        }];

        localStorage.setItem("LessonReportArray", JSON.stringify(newTestDetails));
        setSelectedQuestionNumber(1);
        setAttemptedQuestions([]);
      }
    }
  }, [testQuestions])


  useEffect(() => {
    if (selectedQuestionNumber) {
      const allTestsReportsArray = JSON.parse(localStorage.getItem("LessonReportArray"));
      console.log(allTestsReportsArray)
      if (allTestsReportsArray) {
        const currentTest = allTestsReportsArray?.find((test) => test.sectionId == lessonData.SectionID);

        if (selectedQuestionNumber != testQuestions.length + 1) {
          currentTest.lastQuestion = selectedQuestionNumber;
        }

        const userResponseObjForCurrentTest = currentTest?.userResponseObj
        let questionObj = userResponseObjForCurrentTest?.find((question) => question.questionNumber == selectedQuestionNumber)
        console.log(questionObj)
        setAnswerOfCurrentQuestion(questionObj?.selectedOption)

        console.log(selectedQuestionNumber)
        console.log(questionObj)
        if (questionObj) {
          // questionObj.markForReview = false;
          questionObj.isQuestionViewed = true;
        }
        localStorage.setItem("LessonReportArray", JSON.stringify(allTestsReportsArray));

        setAttemptedQuestions(userResponseObjForCurrentTest?.filter((question) => question.selectedOption)?.map((question) => question.questionNumber));

        setMarkForReviewQuestionsArray(userResponseObjForCurrentTest?.filter((question) => question.markForReview == true)?.map((question) => question.questionNumber))
        setViewedQuestionsArray(userResponseObjForCurrentTest?.filter((question) => question.isQuestionViewed == true)?.map((question) => question.questionNumber))
      }
    }
    let selectedQuestion = testQuestions.find(question => question.QNo == selectedQuestionNumber)
    setSelectedQuestion(selectedQuestion)
  }, [selectedQuestionNumber, testQuestions, answerOfCurrentQuestion])













  const renderhtml1 = (paragraphs) => {
    return <b className='renderhtml1' dangerouslySetInnerHTML={{ __html: paragraphs }} />;
  };

  const selectAnswerHandler = (value) => {
    setAnswerOfCurrentQuestion(value)
    const allTestsReportsArray = JSON.parse(localStorage.getItem("LessonReportArray"));

    const currentTest = allTestsReportsArray?.find((test) => test.sectionId == lessonData.SectionID);

    const selectedQuestionObj = currentTest?.userResponseObj?.find(questionObj => questionObj.questionNumber === selectedQuestionNumber);
    selectedQuestionObj.selectedOption = value;
    localStorage.setItem("LessonReportArray", JSON.stringify(allTestsReportsArray));
  }
  const handleClear = () => {
    const allTestsReportsArray = JSON.parse(localStorage.getItem("LessonReportArray"));
    const currentTest = allTestsReportsArray?.find((test) => test.sectionId==lessonData.SectionID);
    console.log(currentTest)
    const selectedQuestionObj = currentTest?.userResponseObj?.find(questionObj => questionObj.questionNumber === selectedQuestionNumber);
    selectedQuestionObj.selectedOption = '';
    localStorage.setItem("LessonReportArray", JSON.stringify(allTestsReportsArray));
    setAnswerOfCurrentQuestion('')
  }




  const handleFinishTest = () => {
    swal({
      title: "Are you sure?",
      text: "Once finished, you will not be able to return to the test",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((yes) => {
      if (yes) {
        const allTestsReportsArray = JSON.parse(localStorage.getItem("LessonReportArray"));
        const currentTest = allTestsReportsArray?.find((test) => test.sectionId==lessonData.SectionID);
        currentTest.isTestFinished = true;
        localStorage.setItem("LessonReportArray", JSON.stringify(allTestsReportsArray));
        setFinishTest(true)
        swal(
          "Test Finished! Please submit your test."
        )
      }
    })
  }
  const deleteReportFromLS = () => {
    const indexOfCurrentTest = allTestsReportsArray?.findIndex((test) => test.sectionId==lessonData.SectionID);
    allTestsReportsArray.splice(indexOfCurrentTest, 1)
    localStorage.setItem("LessonReportArray", JSON.stringify(allTestsReportsArray));

  }

  const increaseAttemptCount = async () => {
    const response = await axios.post(BASE_URL + `/increaseQuizAttemptCount/${lessonData.SectionID}`)
  }


  const finalSubmitTest = async () => {
    try {
      const currentTest = allTestsReportsArray?.find((test) => test.sectionId==lessonData.SectionID);
      let totalQuestions = lessonData.QuestionCount;
      let attemptedQuestions = 0;
      let correctQuestions = 0;
      let wrongQuestions = 0;
      let correctMarks = 0;
      let wrongMarks = 0;
      let startTime = currentTest.startTime;    

      let userResponseObj = currentTest.userResponseObj;
    

      userResponseObj.map(obj => {
        if (obj.selectedOption != '') {
          attemptedQuestions++;
          if (obj.correctOption == obj.selectedOption) {
            correctMarks = correctMarks + Number(lessonData.MarksPerQuestion)
            correctQuestions++;
          } else {
            wrongMarks = wrongMarks + Number(lessonData.NegativeMarks)
            wrongQuestions++;
          }
        }
      })
      let quizReport = { TotalQuestions: totalQuestions, AttemptedQuestions: attemptedQuestions, CorrectQuestions: correctQuestions, WrongQuestions: wrongQuestions, CorrectMarks: correctMarks, WrongMarks: wrongMarks, StartTime:startTime, AnswerSheet: JSON.stringify(userResponseObj) }
      if (currentTest?.reAttempt === false) {
        const response = await axios.post(BASE_URL + `/insertQuizReport/${lessonData.SectionID}`, quizReport)
        if (response.data.statusCode == 200) {
          deleteReportFromLS();
          swal(response.data.message, {
            icon: "success",
            buttons: {
              confirm: {
                text: "OK",
                value: true,
                visible: true,
                closeModal: true
              }
            }
          }).then(() => {
            navigate('/course-lesson-result', { state: { lessonData, courseData } })
          })
        }
      } else {     
        deleteReportFromLS();
        increaseAttemptCount();
        navigate('/course-lesson-result', { state: { quizReport, lessonData, courseData } })
      }
    } catch (error) {
      swal("Oops", "Something went wrong! Please Try Again", { icon: "error" })
      console.log(error)
    }


  }

  const [unsavedChanges, setUnsavedChanges] = useState(true);
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (unsavedChanges) {
        const message = "Leave site? Changes you made may not be saved.";
        event.returnValue = message;  // Standard for most browsers
        return message;  // For some older browsers
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [unsavedChanges]);


  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (unsavedChanges) {
        const message = "Leave site? Changes you made may not be saved.";
        event.returnValue = message;  // Standard for most browsers
        return message;  // For some older browsers
      }
    };
    window.addEventListener('popState', handleBeforeUnload);

    return () => {
      window.removeEventListener('popState', handleBeforeUnload);
    };
  }, [unsavedChanges]);

 




  return (
    <>
      <div className="" /* style={{height:'90vh', overflow: "scroll" }} */>
              <h5 className='bg-warning p-3 d-inline-flex rounded-pill m-2'><FcAlarmClock className='me-2' /> Time Remaining: <b className='text-danger mx-2'>{String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}</b></h5>              
        <div className='card questionpreviewpadding' >
                <p><b>{selectedQuestion?.QNo}. {renderhtml1(selectedQuestion?.Question)}</b></p>
          <ul className='answer-list' >
                  <li className={answerOfCurrentQuestion?.includes('A') ? 'selected' : ''} onClick={() => selectAnswerHandler('A')}>
                    <input
                      type="radio"
                      className='me-2 mb-1'
                      style={{ width: "20px", height: "20px" }}
                      name='option1'
                      id='option_1'
                      onClick={() => selectAnswerHandler('A')}
                      checked={answerOfCurrentQuestion?.includes('A')}
                    />
                    <label htmlFor='option_1'>
                      <b>(A) {renderhtml1(selectedQuestion?.OptionA)}</b>
                    </label>
                  </li>
                  <li className={answerOfCurrentQuestion?.includes('B') ? 'selected' : ''} onClick={() => selectAnswerHandler('B')}>
                    <input
                      type="radio"
                      className='me-2 mb-1'
                      style={{ width: "20px", height: "20px" }}
                      name='option2'
                      id='option_2'
                      onClick={() => selectAnswerHandler('B')}
                      checked={answerOfCurrentQuestion?.includes('B')}
                    />
                    <label htmlFor='option_2'>
                      <b>(B) {renderhtml1(selectedQuestion?.OptionB)}</b>
                    </label>
                  </li>
                  <li className={answerOfCurrentQuestion?.includes('C') ? 'selected' : ''} onClick={() => selectAnswerHandler('C')}>
                    <input
                      type="radio"
                      className='me-2 mb-1'
                      style={{ width: "20px", height: "20px" }}
                      name='option3'
                      id='option_3'
                      onClick={() => selectAnswerHandler('C')}
                      checked={answerOfCurrentQuestion?.includes('C')}
                    />
                    <label htmlFor='option_3'>
                      <b>(C) {renderhtml1(selectedQuestion?.OptionC)}</b>
                    </label>
                  </li>
                  <li className={answerOfCurrentQuestion?.includes('D') ? 'selected' : ''} onClick={() => selectAnswerHandler('D')}>
                    <input
                      type="radio"
                      className='me-2 mb-1'
                      style={{ width: "20px", height: "20px" }}
                      name='option4'
                      id='option_4'
                      onClick={() => selectAnswerHandler('D')}
                      checked={answerOfCurrentQuestion?.includes('D')}
                    />
                    <label htmlFor='option_4'>
                      <b>(D) {renderhtml1(selectedQuestion?.OptionD)}</b>
                    </label>
                  </li>

                </ul>
              </div>
              <div className='p-3 d-flex justify-content-betweenn flex-wrap'>
                <div className='col-lg-8 py-1 pe-3 col-md-8 col-sm-8 d-flex align-items-start flex-nowrap'>
                  {selectedQuestionNumber > 1 && <button type="button" className="btn bg-Question-white btn-md me-3" onClick={() => setSelectedQuestionNumber(selectedQuestionNumber - 1)}>Previous</button>}
                  <button type="button" className="btn bg-Question-white btn-md" onClick={handleClear}>clear</button>      
                </div>
                <div className='col-lg-4 py-1 col-md-2 col-sm-4 d-flex align-items-start justify-content-end'>
                  {selectedQuestionNumber < lessonData.QuestionCount ? 
                  <button type="button" className='btn bg-Question-white btn-md'  onClick={() => setSelectedQuestionNumber(selectedQuestionNumber + 1)}>Save & Next</button>
                  :
            <button type="button" className='btn bg-Question-white btn-md' onClick={finalSubmitTest}>Submit Test</button>
                  }
                </div>
              </div>
      </div>
         
    </>
  )
}

export default QuestionLayoutreattempted































































// import React, { useEffect, useRef, useState } from 'react';
// import "./QuestionLayout.css"
// import Questionsummary from '../Questionsummary/Questionsummary';
// import Questionpreview1 from '../Questionpreview/Questionpreview1';
// import ResultPreview from '../../ResultPreview/ResultPreview';
// import { Link, useNavigate } from 'react-router-dom';
// import { useAlert } from 'react-alert';
// import { finalsubmitcoursetest, finalsubmittest } from '../../DynamicCourse/api';
// import Questionpreviewreattempted from '../Questionpreview/Questionpreviewreattempted';
// import swal from "sweetalert";

// function QuestionLayoutreattempted({ setcontentToShow, contentToShow, setreportcourseaarray, setuserpassword, prevPath, testQuestion, CourseDuration, coursestatus, reattemptedcourse, testDuration, QuestionCount, coursesectionId, positivemarks, negativemarks, coursetopicsn, testpackageid, authtoken, setPositiveScore, setNegativeScore, setcoursecorrectdataarray, setcourseincorrectdataarray, PositiveScore, NegativeScore, correctAnswers, incorrectAnswers }) {
//   const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
//   const [sessionid, setsessionid] = useState(null);
//   const [useranswersheet, setAnswersheet] = useState(() => {
//     const storedAnswersheet = localStorage.getItem(`${coursesectionId}${authtoken}answersheet`);
//     return storedAnswersheet ? JSON.parse(storedAnswersheet) : [];
//   });

 

//   const alert = useAlert();
//   const navigate = useNavigate();
//   const [queslength, setqueslength] = useState(() => {
//     const storedQuestionArrayString = localStorage.getItem(`${coursetopicsn}questionArray`);
//     return storedQuestionArrayString ? JSON.parse(storedQuestionArrayString) : [];
//   })
//   // const alert = useAlert()
//   const [finish, setfinish] = useState(false);
//   // const [clearoption, setClearOption] = useState(null)
//   const [clearoption, setClearOption] = useState(() => {
//     const storedClearOption = localStorage.getItem(`${coursesectionId}${authtoken}${currentQuestionIndex}clearoption`);
//     return storedClearOption ? JSON.parse(storedClearOption) : null;
//   });

//   useEffect(() => {   
//     const userdata = localStorage.getItem(`${coursesectionId}${authtoken}userdata`);
//     for (let i = 0; i < queslength.length; i++) {
//       if (!userdata) {
//         const isdefault = localStorage.removeItem(`${coursesectionId}${authtoken}${i}clearoption`);
//         setClearOption(isdefault)
//       }
//     }

//   }, [clearoption, coursesectionId])

//   const [userstorage, setUserstorage] = useState(() => {
//     const userdata = localStorage.getItem(`${coursesectionId}${authtoken}userdata`);
//     return userdata ? JSON.parse(userdata) : [];
//   });



//   //  (userstorage)
//   const [unanswereddata, setunanswereddata] = useState(() => {
//     const nouserdata = localStorage.getItem(`${coursesectionId}${authtoken}nouserdata`);
//     return nouserdata ? JSON.parse(nouserdata) : [];
//   });

//   useEffect(() => {
//     // Combine userstorage and unanswereddata into answersheet array
//     const updatedAnswersheet = [...userstorage, ...unanswereddata].sort((a, b) => a.questionindex - b.questionindex);
//     setAnswersheet(updatedAnswersheet);
//     localStorage.setItem(`${coursesectionId}${authtoken}answersheet`, JSON.stringify(updatedAnswersheet));
//   }, [userstorage, unanswereddata, coursesectionId, authtoken]);

//   // Update Userstorage and Unanswereddata array
//   const updateUnansweredData = () => {
//     const userDataJSON = localStorage.getItem(`${coursesectionId}${authtoken}userdata`);
//     const nouserDataJSON = localStorage.getItem(`${coursesectionId}${authtoken}nouserdata`);
//     if (userDataJSON && nouserDataJSON) {
//       const userData = JSON.parse(userDataJSON);
//       const nouserData = JSON.parse(nouserDataJSON);
//       const answeredQuestions = userData.map(data => data.questionindex);
//       const remainingUnanswered = nouserData.filter(data => !answeredQuestions.includes(data.questionindex));
//       setunanswereddata(remainingUnanswered);
//       const unuserAnswerData = nouserData.find(userdata => userdata.questionindex === currentQuestionIndex);
//       if (unuserAnswerData) {
//         setClearOption(null);
//       }
//     }
//   };

//   // Call a function when User not attempted Question and move to next Question
//   const noattemedAnswer = (currentQuestionIndex, answer, answerindex, answercheck) => {
//     // Retrieve existing user data array from local storage
//     const existingUserDataJSON = localStorage.getItem(`${coursesectionId}${authtoken}nouserdata`);
//     let existingUserData = [];
//     if (existingUserDataJSON) {
//       existingUserData = JSON.parse(existingUserDataJSON);
//     }

//     // Update or append the user's answer for the current question
//     const updatedUserData = existingUserData.map(nouserdata => {
//       if (nouserdata.questionindex === currentQuestionIndex) {
//         return { ...nouserdata, QNo: queslength[currentQuestionIndex].QNo, question: queslength[currentQuestionIndex].Question, useranswer: answer, useranswerindex: answerindex, useranswercheck: answercheck, options: queslength[currentQuestionIndex].options, description: queslength[currentQuestionIndex].Description };
//       }
//       return nouserdata;
//     });

//     // If no user data exists for the current question, append it
//     if (!updatedUserData.some(nouserdata => nouserdata.questionindex === currentQuestionIndex)) {
//       updatedUserData.push({ questionindex: currentQuestionIndex, QNo: queslength[currentQuestionIndex].QNo, question: queslength[currentQuestionIndex].Question, useranswer: answer, useranswerindex: answerindex, useranswercheck: answercheck, correctanswer: queslength[currentQuestionIndex].Answer, options: queslength[currentQuestionIndex].options, description: queslength[currentQuestionIndex].Description });
//     }

//     // Store the updated user data array back into local storage
//     localStorage.setItem(`${coursesectionId}${authtoken}nouserdata`, JSON.stringify(updatedUserData));
//     // localStorage.setItem(`${coursesectionId}${authtoken}answersheet`, JSON.stringify(updatedUserData));
//     setunanswereddata(updatedUserData)
//     // setanswersheet(updatedUserData)
//     const userAnswerData = updatedUserData.find(userData => userData.questionindex === currentQuestionIndex);
//     if (userAnswerData) {
//       setClearOption(userAnswerData.useranswerindex);
//       localStorage.setItem(`${coursesectionId}${authtoken}${currentQuestionIndex}clearoption`, userAnswerData.useranswerindex);
//     }
//   };

//   // when User Click a next button then call a function 
//   const handleNextQuestion = () => {
//     // setClearOption(null)
//     const isoption = localStorage.getItem(`${coursesectionId}${authtoken}${currentQuestionIndex}clearoption`)
//     if (!isoption) {
//       noattemedAnswer(currentQuestionIndex, '', null);
//     }

//     // Check if there are more questions to navigate to
//     if (currentQuestionIndex < queslength.length - 1) {
//       setCurrentQuestionIndex(currentQuestionIndex + 1);
//       setClearOption('');
//       const userDataJSON = localStorage.getItem(`${coursesectionId}${authtoken}userdata`);
//       if (userDataJSON) {
//         const userData = JSON.parse(userDataJSON);
//         // Find the user's answer for the next question
//         const nextQuestionAnswer = userData.find(data => data.questionindex === currentQuestionIndex + 1);
//         if (nextQuestionAnswer) {
//           // Set the clear option to the user's selected option for the next question
//           setClearOption(nextQuestionAnswer.useranswerindex);
//         } else {
//           // If the user hasn't answered the next question, clear the option
//           setClearOption(null);
//         }
//       }
//     }
//     // else {
//     //   // If all questions have been answered, redirect to the first question
//     //   // setCurrentQuestionIndex(0);
//     //   const userDataJSON = localStorage.getItem(`${coursesectionId}${authtoken}userdata`);
//     //   if (userDataJSON) {
//     //     const userData = JSON.parse(userDataJSON);
//     //     // Find the user's answer for the first question
//     //     const firstQuestionAnswer = userData.find(data => data.questionindex === 0);
//     //     if (firstQuestionAnswer) {
//     //       // Set the clear option to the user's selected option for the first question
//     //       setClearOption(firstQuestionAnswer.useranswerindex);
//     //     } else {
//     //       // If the user hasn't answered the first question, clear the option
//     //       setClearOption(null);
//     //     }
//     //   }
//     // setClearOption(null)
//     // }

//     if ((currentQuestionIndex + 1) == QuestionCount) {
//       alert.show("Test finished");
//       // handlecheckanswer()
//       setfinish(true)
//       // navigate("/studentdashboard")
//     }


//   };

//   // When User is exist then show selected option in Course 
//   const loadPreviousAnswer = () => {
//     // Retrieve user data from local storage
//     const userDataJSON = localStorage.getItem(`${coursesectionId}${authtoken}userdata`);
//     if (userDataJSON) {
//       const userData = JSON.parse(userDataJSON);
//       setUserstorage(userData);

//       // Check if the previous question index is within the valid range
//       const previousIndex = currentQuestionIndex - 1;
//       if (previousIndex >= 0) {
//         // Check if there is a user answer for the previous question
//         const previousUserData = userData.find(data => data.questionindex === previousIndex);
//         if (previousUserData) {
//           // If there is a user answer for the previous question, set the clear option
//           const userAnswerIndex = previousUserData.useranswerindex;
//           if (userAnswerIndex !== null && userAnswerIndex !== undefined) {
//             setClearOption(queslength[previousIndex].options.indexOf(previousUserData.useranswer));
//           } else {
//             setClearOption(null);
//           }
//         } else {
//           // If there is no user answer for the previous question, clear the selection
//           setClearOption(null);
//         }
//       } else {
//         // If the previous question index is out of range, clear the selection
//         setClearOption(null);
//       }
//     } else {
//       // If there is no user data in local storage, clear the selection
//       setClearOption(null);
//     }
//   };


//   const handlePreviousQuestion = () => {
//     loadPreviousAnswer()

//     if (currentQuestionIndex > 0) {
//       setCurrentQuestionIndex(currentQuestionIndex - 1);
//       // setClearOption('');
//     }
//   };

//   const saveUserAnswer = (currentQuestionIndex, answer, answerindex, answercheck) => {
//     // Retrieve existing user data array from local storage
//     const existingUserDataJSON = localStorage.getItem(`${coursesectionId}${authtoken}userdata`);
//     let existingUserData = [];
//     if (existingUserDataJSON) {
//       existingUserData = JSON.parse(existingUserDataJSON);
//     }

//     // Update or append the user's answer for the current question
//     const updatedUserData = existingUserData.map(userData => {
//       if (userData.questionindex === currentQuestionIndex) {
//         return { ...userData, QNo: queslength[currentQuestionIndex].QNo, question: queslength[currentQuestionIndex].Question, useranswer: answer, useranswerindex: answerindex, useranswercheck: answercheck, options: queslength[currentQuestionIndex].options, description: queslength[currentQuestionIndex].Description };
//       }
//       return userData;
//     });

//     // If no user data exists for the current question, append it
//     if (!updatedUserData.some(userData => userData.questionindex === currentQuestionIndex)) {
//       updatedUserData.push({ questionindex: currentQuestionIndex, QNo: queslength[currentQuestionIndex].QNo, question: queslength[currentQuestionIndex].Question, useranswer: answer, useranswerindex: answerindex, useranswercheck: answercheck, correctanswer: queslength[currentQuestionIndex].Answer, options: queslength[currentQuestionIndex].options, description: queslength[currentQuestionIndex].Description });
//     }

//     // Store the updated user data array back into local storage
//     localStorage.setItem(`${coursesectionId}${authtoken}userdata`, JSON.stringify(updatedUserData));
//     // localStorage.setItem(`${coursesectionId}${authtoken}answersheet`, JSON.stringify(updatedUserData));
//     setUserstorage(updatedUserData)
//     // setanswersheet(updatedUserData)
//     // Set clearoption based on the user's answer index
//     const userAnswerData = updatedUserData.find(userData => userData.questionindex === currentQuestionIndex);
//     if (userAnswerData) {
//       setClearOption(userAnswerData.useranswerindex);
//       localStorage.setItem(`${coursesectionId}${authtoken}${currentQuestionIndex}clearoption`, userAnswerData.useranswerindex);
//     }
//     updateUnansweredData();
//   };



//   const handlecheckanswer = async () => {
//     // Clear previous scores

//     // Retrieve user data from local storage
//     const userDataJSON = localStorage.getItem(`${coursesectionId}${authtoken}userdata`);
//     const userData = JSON.parse(userDataJSON);

//     // Initialize arrays to store correct and incorrect answers
//     let CorrectAnswers = [];
//     let inCorrectAnswers = [];
//     let positiveScore = 0;
//     let negativeScore = 0;

//     // Calculate scores
//     if (userData) {
//       userData.forEach(data => {
//         const correctAnswer = queslength[data.questionindex].Answer;
//         // Check if user answered the question
//         if (data.useranswer !== null && data.useranswer !== undefined) {
//           if (data.useranswercheck === correctAnswer) {
//             positiveScore += Number(positivemarks);
//             CorrectAnswers.push({
//               questionIndex: data.questionindex,
//               QNo: data.QNo,
//               question: data.question,
//               useranswer: data.useranswer, // Use data.useranswer instead of data.useranswercheck
//               useranswercheck: data.useranswercheck, // Use data.useranswercheck
//               correctAnswer: correctAnswer
//             });
//             setcoursecorrectdataarray(CorrectAnswers);
//           } else {
//             negativeScore += Number(negativemarks);
//             inCorrectAnswers.push({
//               questionIndex: data.questionindex,
//               QNo: data.QNo,
//               question: data.question,
//               useranswer: data.useranswer, // Use data.useranswer instead of data.useranswercheck
//               useranswercheck: data.useranswercheck, // Use data.useranswer instead of data.useranswercheck
//               correctAnswer: correctAnswer
//             });
//             setcourseincorrectdataarray(inCorrectAnswers);
//           }
//         }
//       });
//     }
//     // setpositivemarks={setpositivemarks} setnegativemarks={setnegativemarks}
//     // setpositivemarks(positiveScore);
//     // setpositivemarks(parseFloat(negativeScore.toFixed(2)));



//     // Store correct and incorrect answers in local storage
//     localStorage.setItem(`${coursesectionId}${authtoken}correctAnswers`, JSON.stringify(CorrectAnswers));
//     localStorage.setItem(`${coursesectionId}${authtoken}incorrectAnswers`, JSON.stringify(inCorrectAnswers));


//     // Store scores in local storage
//     // localStorage.setItem('positivemarks', JSON.stringify(positiveScore));
//     // localStorage.setItem('negativemarks', JSON.stringify(negativeScore));
//     try {
//       // email, totalQuestions, attemptedQuestions, correctQuestions, wrongQuestions, correctMarks, wrongMarks
//       // sectionId, topicId
//       // Call the finalsubmittest function with the necessary parameters

//       const responseData = await finalsubmitcoursetest({
//         email: authtoken,
//         totalQuestions: queslength.length,
//         attemptedQuestions: userstorage.length,
//         correctQuestions: CorrectAnswers.length,
//         wrongQuestions: inCorrectAnswers.length,
//         correctMarks: positiveScore,
//         wrongMarks: negativeScore,
//         sectionId: coursesectionId,
//         topicId: coursetopicsn,
//         answerSheet: JSON.stringify(useranswersheet),
//         reattemptedcourse,
//         coursestatus
//       });

//       // Log response data

//       // Navigate to the final result page
//       navigate('/CourseDescreption/CourseResult');

//       // Show success alert
//       alert.show('Test submitted successfully');
//     } catch (error) {
//       // Show error alert if submission fails
//       alert.show('Test submission failed. Please try again.');
//     }
//   };

//   function handlecheckanswer1(){
//     // alert.show("this is working fine right now i am working reattpemted modlues")
//     // Clear previous scores

//     // Retrieve user data from local storage
//     const userDataJSON = localStorage.getItem(`${coursesectionId}${authtoken}userdata`);
//     const userData = JSON.parse(userDataJSON);

//     // Initialize arrays to store correct and incorrect answers
//     let CorrectAnswers = [];
//     let inCorrectAnswers = [];
//     let positiveScore = 0;
//     let negativeScore = 0;

//     // Calculate scores
//     if (userData) {
//       userData.forEach(data => {
//         const correctAnswer = queslength[data.questionindex].Answer;
//         // Check if user answered the question
//         if (data.useranswer !== null && data.useranswer !== undefined) {
//           if (data.useranswercheck === correctAnswer) {
//             positiveScore += Number(positivemarks);
//             CorrectAnswers.push({
//               questionIndex: data.questionindex,
//               QNo: data.QNo,
//               question: data.question,
//               useranswer: data.useranswer, // Use data.useranswer instead of data.useranswercheck
//               useranswercheck: data.useranswercheck, // Use data.useranswercheck
//               correctAnswer: correctAnswer
//             });
//             setcoursecorrectdataarray(CorrectAnswers);
//           } else {
//             negativeScore += Number(negativemarks);
//             inCorrectAnswers.push({
//               questionIndex: data.questionindex,
//               QNo: data.QNo,
//               question: data.question,
//               useranswer: data.useranswer, // Use data.useranswer instead of data.useranswercheck
//               useranswercheck: data.useranswercheck, // Use data.useranswer instead of data.useranswercheck
//               correctAnswer: correctAnswer
//             });
//             setcourseincorrectdataarray(inCorrectAnswers);
//           }
//         }
//       });
//     }
//     // setpositivemarks={setpositivemarks} setnegativemarks={setnegativemarks}
//     // setpositivemarks(positiveScore);
//     // setpositivemarks(parseFloat(negativeScore.toFixed(2)));



//     // Store correct and incorrect answers in local storage
//     localStorage.setItem(`${coursesectionId}${authtoken}correctAnswers`, JSON.stringify(CorrectAnswers));
//     localStorage.setItem(`${coursesectionId}${authtoken}incorrectAnswers`, JSON.stringify(inCorrectAnswers));


//     // Store scores in local storage
//     // localStorage.setItem('positivemarks', JSON.stringify(positiveScore));
//     // localStorage.setItem('negativemarks', JSON.stringify(negativeScore));
//     const responseData = {
//       email: authtoken,
//       totalQuestions: queslength.length,
//       attemptedQuestions: userstorage.length,
//       correctQuestions: CorrectAnswers.length,
//       wrongQuestions: inCorrectAnswers.length,
//       correctMarks: positiveScore,
//       wrongMarks: negativeScore,
//       sectionId: coursesectionId,
//       topicId: coursetopicsn,
//       answerSheet: JSON.stringify(useranswersheet),
//       reattemptedcourse,
//       coursestatus
//     };

//     setreportcourseaarray(responseData)
//     // try {
//       //   // email, totalQuestions, attemptedQuestions, correctQuestions, wrongQuestions, correctMarks, wrongMarks
//       //   // sectionId, topicId
//       //   // Call the finalsubmittest function with the necessary parameters
      
      
      
//       // if(responseData){
//         // }
//         // window.location.href = '/CourseDescreption/CourseReattemptedResult';
        
//         localStorage.setItem(`${coursesectionId}${authtoken}reportcourseaarray`, JSON.stringify(responseData));
        
        
//         //   // Log response data
        
//         //   // Navigate to the final result page
        
        
//         //   // Show success alert
//       alert.show('Test submitted successfully');
//       setTimeout( ()=>{  
//         navigate('/CourseDescreption/CourseReattemptedResult');
//       },100)
//       // navigate('/CourseDescreption/CourseReattemptedResult');
//     // } catch (error) {
//     // //   // Show error alert if submission fails
//     //   alert.show('Test submission failed. Please try again.');
//     // }
//   }




//   const clearAnswer = () => {
//     // Retrieve userData array from local storage
//     const userDataJSON = localStorage.getItem(`${coursesectionId}${authtoken}userdata`);
//     if (userDataJSON) {
//       const userData = JSON.parse(userDataJSON);
//       // Remove the user's answer for the current question
//       const updatedUserData = userData.filter(data => data.questionindex !== currentQuestionIndex);
//       // Update the userData array in local storage
//       localStorage.setItem(`${coursesectionId}${authtoken}userdata`, JSON.stringify(updatedUserData));
//       // Clear the selected option
//       setUserstorage(updatedUserData);
//       // setClearOption(null);
//     }

//     // Retrieve nouserData array from local storage
//     const nouserDataJSON = localStorage.getItem(`${coursesectionId}${authtoken}nouserdata`);
//     if (nouserDataJSON) {
//       const nouserData = JSON.parse(nouserDataJSON);
//       // Remove the user's answer for the current question
//       const updatedUserData1 = nouserData.filter(data => data.questionindex !== currentQuestionIndex);
//       // Update the nouserData array in local storage
//       localStorage.setItem(`${coursesectionId}${authtoken}nouserdata`, JSON.stringify(updatedUserData1));
//       // Clear the selected option
//       setunanswereddata(updatedUserData1);
//       // setClearOption(null);
//     }
//   };

//   // Function to handle clearing the answer
//   const handleClear = () => {
//     clearAnswer();
//     const isclear = localStorage.removeItem(`${coursesectionId}${authtoken}${currentQuestionIndex}clearoption`);
//     setClearOption(isclear)
//   };

//   function showCustomAlert1() {
//     document.getElementById('customAlert1').style.display = 'flex';
//   }

//   function hideCustomAlert1() {
//     document.getElementById('customAlert1').style.display = 'none';
//   }

//   function confirmBtn1() {
//     hideCustomAlert1()
//     // setfinish(true)
//     localStorage.removeItem(`${coursesectionId}${authtoken}userdata`);
//     // setUserstorage([])
//     // navigate("/")
//     // navigate(prevPath)
//     setcontentToShow("PT MCQs")
//     setuserpassword(false)
//   }

//   function cancelBtn1() {
//     hideCustomAlert1()
//   }

//   const showAlert1 = () => {

//     showCustomAlert1()
//   };

//   // useEffect(() => {

//   //   const handleBeforeUnload = (event) => {
//   //     event.preventDefault();
//   //     event.returnValue = ""; // Modern browsers will show a default message
//   //   };

//   //   window.addEventListener('beforeunload', handleBeforeUnload);

//   //   return () => {
//   //     window.removeEventListener('beforeunload', handleBeforeUnload);
//   //   };
//   // }, []);


//   //handle mousemove to restrict student
//   const inputRef = useRef(null)
//   const [firstWarning, setFirstWarning] = useState(false);
//   const [mouseFirstMovement, setMouseFirstMovement] = useState(false);
//   const [warning, setWarning] = useState(false);
//   const testAreaRef = useRef(null);
//   // const navigate = useNavigate();

//   console.log(warning)

//   useEffect(() => {
//     const handleMouseMove = (event) => {
//       setMouseFirstMovement(true)
//       const testArea = testAreaRef.current;
//       if (testArea) {
//         const { top, left, width, height } = testArea.getBoundingClientRect();
//         const insideTestArea = (
//           event.clientX >= left &&
//           event.clientX <= left + width &&
//           event.clientY >= top &&
//           event.clientY <= top + height
//         );

//         if (!insideTestArea) {
//           setWarning(true);
//         } else {
//           setWarning(false);
//         }
//       }
//     };

//     document.addEventListener('mouseover', handleMouseMove);

//     return () => {
//       document.removeEventListener('mouseover', handleMouseMove);
//     };
//   }, []);

//   useEffect(() => {
//     if (warning && mouseFirstMovement) {
//       swal({
//         title: "Are you sure?",
//         text: "Do you really want to leave the test for now!",
//         icon: "warning",
//         buttons: true,
//         dangerMode: true,
//         closeOnClickOutside: false,
//         buttons: ["No, stay Here", "Yes, leave the test"],
//         confirmButtonText: "Yes, leave the test"
//       }).then((willDelete) => {
//         if (willDelete) {
//           navigate('/home')
//         } else {
//           console.log('stay here')
//         }
//       })
//     }
//   }, [warning])



//   return (
//     <>
//       <div id="customAlert1" class="custom-alert1" style={{ zIndex: "165" }}>
//         <div class="custom-alert-content">
//           <span class="custom-alert-icon">⚠️</span>
//           <p>Are you sure you want to quit the test?</p>
//           <div class="custom-alert-buttons">
//             <button onClick={confirmBtn1} id="confirmBtn1">Yes</button>
//             <button onClick={cancelBtn1} id="cancelBtn1">No</button>
//           </div>
//         </div>
//       </div>
//       <div className='container-fluid p-3' ref={testAreaRef}>
//         <div className='row'>
//           <div className='col-lg-12'>
//             <Questionpreviewreattempted setcontentToShow={setcontentToShow} setfinish={setfinish} coursesectionId={coursesectionId} setuserpassword={setuserpassword} finish={finish} testDuration={testDuration} CourseDuration={CourseDuration} coursetopicsn={coursetopicsn} currentQuestionIndex={currentQuestionIndex} authtoken={authtoken} testpackageid={testpackageid} queslength={queslength} unanswereddata={unanswereddata} setqueslength={setqueslength} clearoption={clearoption} userstorage={userstorage} setClearOption={setClearOption} setUserstorage={setUserstorage} saveUserAnswer={saveUserAnswer} setsessionid={setsessionid} />
//             {!finish ? <div className='p-3 d-flex justify-content-between flex-wrap'>
//               <div className='col-lg-5 py-3 col-md-12 respons-btn1 col-sm-12 d-flex flex-wrap'>
//                 {currentQuestionIndex <= 0 ? <></> : <button type="button" className="btn bg-Question-white btn-md me-2 px-5" onClick={handlePreviousQuestion}>Previous</button>}
//                 <button type="button" className="btn bg-Question-white btn-md me-2 px-5" onClick={handleClear}>Clear Answer</button>
//               </div>
//               <div className='col-lg-5 py-3 col-md-2 col-sm-12 respons-btn2 d-flex flex-wrap justify-content-end'>
//                 {/* <button type="button" className="btn bg-Question-white btn-md px-5 me-4" onClick={showAlert1}>Test Quit</button> */}
//                 <button type="button" className="btn bg-Question-white btn-md px-5" onClick={handleNextQuestion}>{((currentQuestionIndex + 1) == QuestionCount) ? "Final Submit" : "Save & Next"}</button>
//               </div>
//             </div> : <div className='p-2 d-flex justify-content-around'>
//               {coursestatus === "Incomplete" ? <Link type="button" className="btn bg-Question-white btn-md" onClick={handlecheckanswer} to="#">Final Submit</Link> : <Link type="button" className="btn bg-Question-white btn-md" onClick={handlecheckanswer1} to="#">Final Submit</Link> }
//             </div>}
//           </div>
//         </div>
//       </div>
//     </>
//   )
// }

// export default QuestionLayoutreattempted;