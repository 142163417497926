import React, { useEffect, useState } from 'react'
import CardsPage from '../CardsPage/CardsPage'
import { fetchMyCourse } from '../DynamicCourse/api';
import axios from 'axios';
import { BASE_URL } from '../../insightConfig/insightConfig';

function MyCourses({ heading }) {
  const [mycourse, setMycourse] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/mycourse`)
        console.log(response);
        setMycourse(response?.data?.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <CardsPage cards={mycourse} heading={heading} />
    </>
  )
}

export default MyCourses
