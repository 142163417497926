import React, { useEffect, useState } from 'react';
import "./Banner.css";
import { Link } from 'react-router-dom';
import { saveUserActivity } from '../../commonServices/userTrackingService';
import axios from 'axios';
import { BASE_URL } from '../../insightConfig/insightConfig';
import { selectNewSubjectAndSectionRandomly } from '../../commonServices/commonService';

function Banner({ QuizQuestionurl }) {
    const [subjects, setSubjects] = useState([]);
    const [dynamicSubjectURL, setDynamicSubjectURL] = useState([]);
    const [dynamicSectionURL, setDynamicSectionURL] = useState([]);
    // console.log(subjects);
    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.get(`${BASE_URL}/getSubjectAndSections`);
            let subjects = response?.data?.data;
            setSubjects(subjects);
            const response1 = selectNewSubjectAndSectionRandomly(subjects);
            setDynamicSubjectURL(response1.subjectURL);
            setDynamicSectionURL(response1.sectionURL);
        }
        fetchData()
    }, []);

    return (
        <>
            {/* Banner Section Start */}
            <div className='container-fluid Banner-background py-1'>
                <div className='row banner-box '>
                    {/* Card Section Start */}

                    <div className="d-md-flex justify-content-between">
                        <div className="col-md-6 d-flex justify-content-between justify-content-md-around">
                            <Link className=" text-center card-background list-group-item" to="/civils" onClick={() => saveUserActivity("click", "clicked on upsc course tab", "/civils")}>
                                <div className="bann-card ">
                                    <h5 className="banner-title">UPSC</h5>
                                    <div style={{ height: "0.1px", backgroundColor: "white", margin: "0" }} />
                                    <p className="banner-paragraph mb-2">Civil Services Courses</p>
                                </div>
                            </Link>

                            <Link className="card text-center card-background list-group-item" to="/civilstestseries" onClick={() => saveUserActivity("click", "clicked on upsc test series tab", "//civilstestseries")}>
                                <div className="bann-card">
                                    <h5 className="banner-title">UPSC</h5>
                                    <div style={{ height: "0.1px", backgroundColor: "white", margin: "0" }} />
                                    <p className="banner-paragraph mb-2">Civil Services Test Series</p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-6 d-flex justify-content-between justify-content-md-around">
                            <Link className="card text-center card-background list-group-item" to="/cuet" onClick={() => saveUserActivity("click", "clicked on cuet tab", "/cuet")}>
                                <div className="bann-card">
                                    <h5 className="banner-title">CUET</h5>
                                    <div style={{ height: "0.1px", backgroundColor: "white", margin: "0" }} />
                                    <p className="banner-paragraph mb-2">University Entrance Prep</p>
                                </div>
                            </Link>

                            <Link className="card text-center card-background list-group-item" onClick={() => saveUserActivity("click", "clicked on neet tab", "/neet")}>
                                <div className="bann-card">
                                    <h5 className="banner-title">NEET</h5>
                                    <div style={{ height: "0.1px", backgroundColor: "white", margin: "0" }} />
                                    <p className="banner-paragraph mb-2">Medical Preparation</p>
                                </div>
                            </Link>
                        </div>

                    </div>
                    <div className="d-md-flex justify-content-between">
                        <div className="col-md-6 d-flex justify-content-between justify-content-md-around">
                            <Link className="card text-center card-background list-group-item" to="/daily-current-affairs/News" onClick={() => saveUserActivity("click", "clicked on News tab", "/daily-current-affairs/News")}>
                                <div className="bann-card">
                                    <h5 className="banner-title">NEWS</h5>
                                    <div style={{ height: "0.1px", backgroundColor: "white", margin: "0" }} />
                                    <p className="banner-paragraph mb-2">Daily Current Affairs</p>
                                </div>
                            </Link>

                            <Link to={`/upsc-quiz/${dynamicSubjectURL}/${dynamicSectionURL}`} className="card text-center card-background list-group-item" onClick={() => saveUserActivity("click", "clicked on upsc quiz tab", "/upsc-quiz")}>
                                <div className="bann-card">
                                    <h5 className="banner-title">QUIZ</h5>
                                    <div style={{ height: "0.1px", backgroundColor: "white", margin: "0" }} />
                                    <p className="banner-paragraph mb-2">Test Yourself And Succeed</p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-6 d-flex justify-content-between justify-content-md-around">
                            <Link className="card text-center card-background list-group-item" to="/blogs" onClick={() => saveUserActivity("click", "clicked on blog tab", "/blogs")}>
                                <div className="bann-card">
                                    <h5 className="banner-title">BLOGS</h5>
                                    <div style={{ height: "0.1px", backgroundColor: "white", margin: "0" }} />
                                    <p className="banner-paragraph mb-2">Expand Your Knowledge</p>
                                </div>
                            </Link>

                            <Link disabled className="card text-center card-background list-group-item" /* to="/books" */ onClick={() => saveUserActivity("click", "clicked on book tab", "/books")}>
                                <div className="bann-card">
                                    <h5 className="banner-title">BOOKS</h5>
                                    <div style={{ height: "0.1px", backgroundColor: "white", margin: "0" }} />
                                    <p className="banner-paragraph mb-2">Keep Learning</p>
                                </div>
                            </Link>
                        </div>
                    </div>

                    {/* Card Section End */}
                </div>
            </div>
            {/* Banner Section End */}
            {/* Animation Section Start */}
            <div className='Boxs'>
                <div className='b1'></div>
                <div className='b2'></div>
                <div className='b3'></div>
                <div className='b4'></div>
                <div className='b5'></div>
                <div className='b6'></div>
            </div>
            {/* Animation Section End */}
        </>
    )
}

export default Banner;
