import React, { useEffect, useState } from 'react'
import CardsPage from '../CardsPage/CardsPage';
import axios from 'axios';
import { BASE_URL } from '../../insightConfig/insightConfig';

function Subscribemore({ heading}) {
  // const [userData, setUserData] = useState([]);
  // const [mycourse, setMycourse] = useState([]);
  const [notmycourse, setNotmycourse] = useState([]);
  const authtoken = localStorage.getItem('authtoken');
  // const [mytest, setMytest] = useState([]);
  // const [notmytest, setNotmytest] = useState([]);
  // useEffect(()=>{
  //   // {cards.map((card) => (
  //   //   setcoursenotpayment(card.paymentdonecourse),
  //   //   setcoursepayment(card.paymentdonecourse)
  //   // ))}
  //   setcoursepayment(0)
  //   setcoursenotpayment(1)
  //   setispayment(false)
  //   localStorage.setItem('ispayment', JSON.stringify(false));
  // },[])

  useEffect(() => {
    const fetchData = async () => {
        try {
          const response=await axios.get(`${BASE_URL}/notmycourse`);
          setNotmycourse(response.data.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    fetchData();
}, [authtoken]); 
  return (
    <>
     <CardsPage cards={notmycourse} heading={heading}/> 
    </>
  )
}

export default Subscribemore;