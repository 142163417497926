import React, { useEffect, useRef, useState } from 'react'
import CivilButton from '../ReusableComponent/CIVIL/CivilButton/CivilButton'
import CourseDescriptionleft from '../CourseDescriptionleft/CourseDescriptionleft'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { fetchCourseDescription } from '../DynamicCourse/api';
import axios from 'axios';


function CourseDescription() {

  const location = useLocation();
  const lessonData = location?.state?.lessonData || {};
  const courseData = location?.state?.courseData || {};
  console.log('lessonData', lessonData)
  console.log('courseData', courseData)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]); 
  
  // useEffect(() => {
  //   const fetchData = async () => {
  //     const response1 = await axios.get(`${BASE_URL}/lesson-details/${lessonData.SectionID}/${storedAuthToken}`);

  //   }
  //   fetchData()
  // }, [])


  let componentName;


  if (lessonData.ShowClassQuestions == 1 && lessonData.ShowPrelims == 1) {
    componentName = "CourseDescriptionprelimstest"
  } else {
    if (lessonData.ShowClassQuestions == 1) {
      componentName = "CourseDescription"
    } else if (lessonData.ShowPrelims == 1) {
      if (lessonData.QuestionCount === 0 || lessonData.QuestionCount === null || lessonData.QuestionCount === undefined) {
        componentName = "WithoutPrelimsDescription"
      } else {
        componentName = "PrelimsDescription"
      }
    } else {
      componentName = "WithoutPrelimsDescription"
    }
  }
  return (
    <>
      <div className='Dashboard-background'>
        <div className='container-fluid'>
          <div className='row'  >
            <div className='col-lg-3 col-md-12 col-sm-12 dashboardmain-left p-3'>
              <CourseDescriptionleft courseDescriptionData={lessonData?.Title} />
            </div>
            <div className='col-lg-9 col-md-12 col-sm-12' >
              <CivilButton courseData={courseData} lessonData={lessonData} componentName={componentName} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CourseDescription
