import React, { useEffect, useState } from 'react';
import CivilButton from '../ReusableComponent/CIVIL/CivilButton/CivilButton';
import Dashboardleft2 from '../DashboardLeft/DashboardLeft2';
import { useLocation, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Loader from '../Loader/Loader';
import axios from 'axios';
import { BASE_URL } from '../../insightConfig/insightConfig';

function DynamicTestseries() {
  const [open, setOpen] = useState(false);
  const { testseriesname } = useParams();

  const [testSeriesData, setTestSeriesData] = useState(null);
  const [reloadTestData, setReloadTestData] = useState(false);
  // const [testSeriesData, setTestSeriesData] = useState(null);
  const componentName = "civilTest";


  // Update prevPath whenever the route changes
  // useEffect(() => {
  //   setPrevPath(location.pathname);
  //   localStorage.setItem('prevPath', location.pathname);
  // }, [location.pathname, setPrevPath]);
  const storedAuthToken = localStorage.getItem('authtoken');
  useEffect(() => {
    axios.get(BASE_URL + `/testseries/${testseriesname}`).then((response) => {
      setTestSeriesData(response.data.data[0])
      console.log(response.data.data)
    }).catch((error) => {
      console.log(error)
    })
  }, [reloadTestData, testseriesname]);

  console.log(testSeriesData)

  if (!testSeriesData) {
    return <div style={{ backgroundColor: "white", zIndex: "180", width: "100%", height: "100vh", position: "fixed", top: "0" }}><Loader /></div>;
  }

  const paragraphs = testSeriesData.Description;
  // const testtoggleData = testSeriesData;

  return (
    <>
      <Helmet>
        <title>{testSeriesData.MetaTitle}</title>
        <meta name="description" content={`${testSeriesData.MetaDescription}`}></meta>
      </Helmet>
      {/* Civil Test Page Start */}
      <div className='Dashboard-background'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-3 col-md-12 col-sm-12 dashboardmain-left p-3'>
              <Dashboardleft2 openPaymentModal={open} setOpenPaymentModal={setOpen} testSeriesData={testSeriesData} reloadTestData={reloadTestData} setReloadTestData={setReloadTestData} />
            </div>
            <div className='col-lg-9 col-md-12 col-sm-12'>
              <CivilButton setOpenPaymentModal={setOpen} testSeriesData={testSeriesData} componentName={componentName} authtoken={storedAuthToken} />
            </div>
          </div>
        </div>
      </div>
      {/* Civil Test Page End */}
    </>
  )
}

export default DynamicTestseries;
