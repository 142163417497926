import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import CardsPage from '../../CardsPage/CardsPage';
import TwoRowThreeColumnLayout from '../../EffectonLoad/TwoThreecolumnLayout';
import { fetchtestCard } from '../../DynamicCourse/api';
import { BASE_URL } from '../../../insightConfig/insightConfig';
import axios from 'axios';

function CivilsTest() {
  const civilheading = "TEST SERIES"
  const [testsData, setTestsData] = useState([]);
  useEffect(() => {
    const storedAuthToken = localStorage.getItem('authtoken');
    axios.get(BASE_URL + `/ExamBodytest/civils/`).then((response) => {
      console.log(response)
      setTestsData(response.data.data);
    }).catch((error) => {
      console.log(error)
    })
  }, []); // Empty dependency array ensures useEffect runs only once after initial render
  if (testsData.length === 0) {
    return <><TwoRowThreeColumnLayout /></>
  }



  return (
    <>
      <Helmet>
        <title>Index</title>
      </Helmet>
      <div>
        <CardsPage cards={testsData} heading={civilheading} />
      </div>
    </>
  )
}

export default CivilsTest;