import React, { useState } from 'react';
import "../Settings/Setting.css";
import { FaLock } from "react-icons/fa";
import { useAlert } from 'react-alert';
import { BASE_URL } from '../../insightConfig/insightConfig';
import axios from 'axios';

function Security() {
    const alert = useAlert();
    const [OldPassword, setOldPassword] = useState('');
    const [NewPassword, setNewPassword] = useState('');
    const [ConfirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');

    const handleUpdatePassword = async (e) => {
        e.preventDefault();

        if (!OldPassword.trim() || !NewPassword.trim() || !ConfirmPassword.trim()) {
            setError('Please fill in all fields');
            return;
        }

        try {
            const response = await axios.post(`${BASE_URL}/updatePassword`, { OldPassword, NewPassword, ConfirmPassword })
            // const response = await fetch(`${BASE_URL}/updatepassword/${authtoken}`, {
            //     method: 'PUT',
            //     headers: {
            //         'Content-Type': 'application/json',
            //     },
            //     body: JSON.stringify({ OldPassword, NewPassword , ConfirmPassword}),
            // });
            // if (response.data.statusCode !== 200) {
            //     throw new Error('Update Password failed');
            // }
            // Reset form fields and state
            setOldPassword('');
            setNewPassword('');
            setConfirmPassword('');
            // Show login success message
            alert.show(response.data.message);
        } catch (error) {
            setError(error.response.data.message);
            alert.show(error?.response?.data?.message)
        }
    };
    return (
        <>
            <div className='Setting-background'>
                <form onSubmit={handleUpdatePassword}>
                    <div className='row'>
                        <div className="col-lg-6">
                            <label className="form-label fw-bold"><FaLock className='me-2' />Current Password</label>
                            <input type="password" className="form-control" value={OldPassword} onChange={(e) => setOldPassword(e.target.value)} />
                        </div>
                        <div className="col-lg-6">
                            <label className="form-label fw-bold"><FaLock className='me-2' /> New Password</label>
                            <input type="password" className="form-control" value={NewPassword} onChange={(e) => setNewPassword(e.target.value)} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-lg-6">
                            <label className="form-label fw-bold"><FaLock className='me-2' />Confirm Password</label>
                            <input type="password" className="form-control" value={ConfirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                        </div>
                    </div>
                    <div className='d-flex align-items-center py-2'>
                        <button type="submit" className="btn btn-save">Update Password</button>
                    </div>
                </form>
                {/* Error message if login fails */}
                {error && <p>{error}</p>}
            </div>
        </>
    )
}

export default Security;