import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export function saveCookie(name, value) {
    cookies.set(name, value, { path: '/', expires: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000) });
}
export function getCookie(name) {
    return cookies.get(name);
}

export function deleteCookie(name) {
    cookies.remove(name, { path: '/', expires: new Date(0) });
}

export function setTokenInHeader() {
    axios.defaults.headers.common['Authorization'] = cookies.get('token');
    return true;
}

