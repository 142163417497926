import React, { useEffect, useState } from 'react';
import "./BlogPage.css";
import InfiniteScroll from 'react-infinite-scroll-component'; // Importing InfiniteScroll
import BlogPage1 from '../BlogPageComponent/BlogPage1';
import Pagelayout2 from '../EffectonLoad/pagelayout2';
import { BASE_URL } from '../../insightConfig/insightConfig';
import CategoryButtons from '../CurrentPage/CategoryButtons';
import axios from 'axios';
import allCoughtUpImage from '../CurrentPage/cought-2.jpg'

function BlogPage() {
    const [categoryId, setCategoryId] = useState(null);

    const [blog, setBlog] = useState([]);
    const [isAllCoughtUp, setIsAllCoughtUp] = useState(false);


    const [hasMore, setHasMore] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [isloading, setisloading] = useState(false);
    const [isfilterapply, setisfilterapply] = useState(false);

    const [startDate, setStartDate] = useState("2020-01-19")
    const [endDate, setEndDate] = useState(() => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    })

    console.log(blog)



    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const data1 = await fetchblog1();
    //             const data2 = await fetchblog2();
    //             setBlog(data1.data);
    //             setToday(data2.data);
    //             setisloading(true)
    //         } catch (error) {
    //             console.error('Error fetching data:', error);
    //             setisloading(false)
    //         }
    //     };
    //     fetchData();
    // }, []);


    const fetchMoreData = async () => {
        try {
            if (isfilterapply) {
                let response = await axios.get(`${BASE_URL}/blogs/${categoryId}/${startDate}/${endDate}/${blog.length}`)
                let newData = response.data.data;
                if (newData.length < 5) {
                    setIsAllCoughtUp(true)
                }
                setBlog(prevNews => [...prevNews, ...newData]);
                setisloading(false);
            } else {
                let response = await axios.get(`${BASE_URL}/blogs/${categoryId}/${blog.length}`)
                let newData = response.data.data;
                if (newData.length < 5) {
                    setIsAllCoughtUp(true)
                }
                setBlog(prevNews => [...prevNews, ...newData]);
                setisloading(false);

            }
        } catch (error) {
            console.error('Error fetching more data:', error);
            setisloading(false);
        }
    };





    const handleViewMoreClickblog = () => {
        setisloading(true)
        fetchMoreData();

    };

    // const handleStopViewMoreClickblog = () => {
    //     setFetching(false)
    //     setHasMore(fetching)
    // };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setisloading(true)
                setisfilterapply(false)
                let response = await axios.get(BASE_URL + `/getBlogsByCategory/${categoryId}`)
                console.log(response.data)
                setBlog(response.data.data)
                setisloading(false)
            } catch (error) {
                console.error('Error fetching data:', error);
                setisloading(false)
            }
        };
        if (categoryId) fetchData();
    }, [categoryId]);



    return (
        <>
            <div className='BlogPage-background'>
                <div className='BlogPage-box1'>
                    <h1>BLOGS - Expand your Knowledge</h1>
                </div>
                <div className='BlogPage-box2'>
                    {/* <Buttontabs componentName={componentName} setContentToShow={setContentToShow} /> */}
                    <CategoryButtons module='blogs' setCategoryId={setCategoryId} />
                </div>
            </div>
            {/* Display Information Section Start */}
            <InfiniteScroll
                dataLength={blog.length}
                // next={handleStopViewMoreClickblog}
                hasMore={hasMore}
                loader={
                    fetching ? <div className='Civil-section blog1-section'>
                        <div className='civil-row white'>
                            <div className='col-lg-8 col-md-9 col-sm-12'>
                                <Pagelayout2 />
                            </div>
                        </div>
                    </div> : null
                }

            >
                <BlogPage1 blogs={blog} categoryId={categoryId} setBlog={setBlog} startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} isloading={isloading} setisfilterapply={setisfilterapply} />

            </InfiniteScroll>
            <div className='Civil-section blog1-section'>
                <div className='civil-row white view-width1 d-flex justify-content-evenly blog-margin-bottom '>
                    {/* {<Link className='list-group-item p-2 px-4 rounded-pill text-white Affair-background' to="#" onClick={handleViewMoreClickblog}>Click to View More</Link>} */}

                    {isAllCoughtUp
                        ?
                        <img className='w-30' /* style={{minWidth:"30%", maxWidth:"40%" ,height:"20%"}} */ src={allCoughtUpImage} alt="" />
                        :
                        blog.length ? <button className='list-group-item p-2 px-4 rounded-pill text-white list-background' onClick={handleViewMoreClickblog}>Click to View More</button> : null
                    }
                </div>
            </div>
        </>
    )
}

export default BlogPage;