import React, { useEffect, useState } from 'react';
import "./CivilSchedule.css";
import CivilScheduleContent from '../CivilScheduleContent/CivilScheduleContent';
import axios from 'axios';
import { BASE_URL } from '../../../../insightConfig/insightConfig';

function CivilSchedule({ testSeriesData }) {
    const [testSeriesTests, setTestSeriesTests] = useState([])

    useEffect(() => {
        axios.get(`${BASE_URL}/test-series-schedule/${testSeriesData?.CourseID}`).then((response) => {
            setTestSeriesTests(response.data.data)
        }).catch((error) => {
            console.log(error)
        })
    }, [testSeriesData])




    return (
        <div className='Civil-Schedule-background p-1'>
            <div className="shadow Civilpadding-schedule mb-5 bg-body rounded p-0">
                <CivilScheduleContent items={testSeriesTests} />
            </div>
        </div>
    )
}

export default CivilSchedule;
