import React, { useEffect, useState } from 'react'
import CardsPage from '../CardsPage/CardsPage';
import axios from 'axios';
import { BASE_URL } from '../../insightConfig/insightConfig';

function Moretest({heading2}) {
 

  const [notmytest, setNotmytest] = useState([]);
  // useEffect(()=>{
  
  // setcoursepayment(0)
  // setcoursenotpayment(1)
  // setispayment(false)
  // localStorage.setItem('ispayment', JSON.stringify(false));
  // },[])
  const authtoken = localStorage.getItem('authtoken');
  useEffect(() => {
    const fetchData = async () => {
        try {    
          const response=await axios.get(`${BASE_URL}/notmytestseries`);           
          setNotmytest(response.data.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    fetchData();
}, [authtoken]); 
  return (
    <div>
      <CardsPage cards={notmytest} heading2={heading2}/>
    </div>
  )
}

export default Moretest;