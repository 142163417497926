import React, { useEffect, useState } from 'react';
import "../../jsx/ReusableComponent/CIVIL/CivilToggle/CivilToggle.css";
import { CiLock } from "react-icons/ci";
// import { CiUnlock } from "react-icons/ci";
import { HiPlay } from "react-icons/hi";
import { Link } from 'react-router-dom';

import axios from 'axios';
import { BASE_URL } from '../../insightConfig/insightConfig';



function BookSubjects({ bookData }) {


    const [courseSubjects, setCourseSubjects] = useState([]);
    const [subjectLessons, setSubjectLessons] = useState([]);
    const storedAuthToken = localStorage.getItem('authtoken');

    console.log(courseSubjects, subjectLessons)




    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/v2/book-subject-and-lessons/${bookData.courseId}`);
                setCourseSubjects(response.data.subjectData)
                let formatedLessons = response.data.lessonData.map(lesson => {
                    return {
                        ...lesson, lessonURL: lesson.Title?.replace(/\s+/g, ' ').toLowerCase().replace(/\s+/g, '-')
                    }
                })
                setSubjectLessons(formatedLessons)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        if (bookData.courseId) fetchData();
    }, [bookData]);

    console.log(subjectLessons)




    return (
        <>
            {<div className='Toggle-container bg-light'>
                <div className="accordion" id="accordionExample">
                    {courseSubjects.map((classItem, index) => (
                        <div className="accordion-item bg-gray m-3" key={index}>
                            <h2 className="accordion-header" id={`heading${index}`}>
                                <button className="accordion-button collapsed button-toggle-container bg-gray" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-controls={`collapse${index}`}>
                                    {`${classItem.Subject}`}
                                </button>
                            </h2>
                            <div id={`collapse${index}`} className="accordion-collapse collapse" aria-labelledby={`heading${index}`} data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <ul className="list-group">
                                        {subjectLessons.filter(obj => obj.SubjectID == classItem.Sn)?.map((section, sectionIndex) => (
                                            <li className="" key={sectionIndex} style={{ listStyle: "none" }}>
                                                {(storedAuthToken && bookData.isEnrolled == 'enrolled' && section.LockStatus !== 'Lock') ? <Link to={`/course-lesson/${section.lessonURL}`} state={{ lessonData: section, courseData: bookData }} className='text-decoration-none'>
                                                    <li className="list-group-item d-flex justify-content-between toggle-list py-3">
                                                        <span className='text-decoration-none'>{section.Title}</span>
                                                        <span><HiPlay className='fs-4 text-danger' /></span>
                                                    </li>
                                                </Link> :
                                                    <Link to="#" className='text-decoration-none' >
                                                        <li className="list-group-item d-flex justify-content-between toggle-list py-3">
                                                            <span>{section.Title}</span>
                                                            <span><CiLock className='fs-4 text-danger' /></span>
                                                        </li>
                                                    </Link>}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>}
        </>
    )
}

export default BookSubjects;
